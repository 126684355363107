export const tableHeader = [
  { value: "action", key: "profile.carparks.action" },
  {
    value: "code",
    key: "profile.carparks.code",
  },
  {
    value: "name",
    key: "profile.carparks.name",
  },
  {
    value: "region",
    key: "profile.carparks.region",
  },
  {
    value: "website",
    key: "profile.carparks.website",
  },
  {
    value: "hotline",
    key: "profile.carparks.hotline",
  },
  {
    value: "status",
    key: "profile.carparks.status",
  },
];

export const status = [
  {
    value: "normal",
    key: "profile.carparks.normal",
  },
  {
    value: "suspend",
    key: "profile.carparks.suspend",
  },
];

export const region = [
  {
    value: "HK",
    key: "profile.carparks.hk",
  },
  {
    value: "KLN",
    key: "profile.carparks.kln",
  },
  {
    value: "NT",
    key: "profile.carparks.nt",
  },
];
