import baseAPIHelper from "./baseAPIHelper";

function getManualHandling(
  searchFilters,
  token,
  successCallback,
  failCallback
) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/report/application/manuelHandling?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchKey === "createdAt") {
      if (searchFilters.createdAt.from) {
        url += `&createdAt[from]=${searchFilters.createdAt.from}`;
      }
      if (searchFilters.createdAt.to) {
        url += `&createdAt[to]=${searchFilters.createdAt.to}`;
      }
      return;
    }

    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function getExceptionalCase(
  searchFilters,
  token,
  successCallback,
  failCallback
) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/report/application/exception?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchKey === "createdAt") {
      if (searchFilters.createdAt.from) {
        url += `&createdAt[from]=${searchFilters.createdAt.from}`;
      }
      if (searchFilters.createdAt.to) {
        url += `&createdAt[to]=${searchFilters.createdAt.to}`;
      }
      return;
    }

    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function getDailyCount(searchFilters, token, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/report/application/dailyApplicationCount?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchKey === "createdAt") {
      if (searchFilters.createdAt.from) {
        url += `&createdAt[from]=${searchFilters.createdAt.from}`;
      }
      if (searchFilters.createdAt.to) {
        url += `&createdAt[to]=${searchFilters.createdAt.to}`;
      }
      return;
    }

    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const reportAPIHelper = {
  getManualHandling,
  getExceptionalCase,
  getDailyCount,
};

export default reportAPIHelper;
