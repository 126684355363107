export const tableHeader = [
  { value: "action", key: "profile.staff.action" },
  {
    value: "name",
    key: "profile.staff.name",
  },
  {
    value: "username",
    key: "profile.staff.username",
  },
  {
    value: "role",
    key: "profile.staff.role",
  },
  {
    value: "status",
    key: "profile.staff.status",
  },
];

export const status = [
  {
    value: "normal",
    key: "profile.staff.normal",
  },
  {
    value: "suspend",
    key: "profile.staff.suspend",
  },
];

export const role = [
  {
    value: "user",
    key: "profile.staff.user",
  },
  {
    value: "staff",
    key: "profile.staff.staff",
  },
  {
    value: "manager",
    key: "profile.staff.manager",
  },
  {
    value: "admin",
    key: "profile.staff.admin",
  },
];
