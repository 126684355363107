import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/common/header/header.component";
import Sidebar from "./components/common/sidebar/sidebar.component";
import Footer from "./components/common/footer/footer.component";
import "./App.scss";
import Home from "./containers/General/Home/home";
import Carparks from "./containers/Profile/Carparks/index";
import Vehicles from "./containers/Profile/Vehicles";
import Staff from "./containers/Profile/StaffAccounts";
import Login from "./containers/Login/login";
import Announcements from "./containers/Profile/Announcements";
import Campaigns from "./containers/Profile/Campaigns";
import Applications from "./containers/Profile/Applications";
import "bootstrap/dist/css/bootstrap.min.css";
import ActivityLogs from "./containers/Profile/ActivityLogs";
import ApplicationGenerator from "./containers/Profile/ApplicationGenerator";
import ApplicationExport from "./containers/Profile/ApplicationExport";
import Report from "./containers/Profile/Reports";
import Setting from "./containers/Profile/Setting";
import IPRestrictions from "./containers/Security/IPRestriction";
import ExportReport from "./containers/Profile/ExportReport";

function PrivateRoute({ children, ...rest }) {
  const { token } = useSelector((state) => state.account);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )}
    />
  );
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/:lang">
            <Login />
          </Route>
          <div className="page-wrapper">
            <Header />
            <div className="page-body-wrapper">
              <Sidebar />
              <div className="page-body">
                <PrivateRoute path="/:lang/home">
                  <Home />
                </PrivateRoute>
                <PrivateRoute path="/:lang/carparks">
                  <Carparks />
                </PrivateRoute>
                <PrivateRoute path="/:lang/vehicles">
                  <Vehicles />
                </PrivateRoute>
                <PrivateRoute path="/:lang/staffaccounts">
                  <Staff />
                </PrivateRoute>
                <PrivateRoute path="/:lang/announcements">
                  <Announcements />
                </PrivateRoute>
                <PrivateRoute path="/:lang/campaigns">
                  <Campaigns />
                </PrivateRoute>
                <PrivateRoute path="/:lang/applications">
                  <Applications />
                </PrivateRoute>
                <PrivateRoute path="/:lang/applicationsRejected">
                  <Applications isRejected />
                </PrivateRoute>
                <PrivateRoute path="/:lang/applicationgenerator">
                  <ApplicationGenerator />
                </PrivateRoute>
                <PrivateRoute path="/:lang/applicationexport">
                  <ApplicationExport />
                </PrivateRoute>
                <PrivateRoute path="/:lang/reports">
                  <Report />
                </PrivateRoute>
                <PrivateRoute path="/:lang/exportReports">
                  <ExportReport />
                </PrivateRoute>
                <PrivateRoute path="/:lang/activitylogs">
                  <ActivityLogs />
                </PrivateRoute>
                <PrivateRoute path="/:lang/setting">
                  <Setting />
                </PrivateRoute>
                <PrivateRoute path="/:lang/iprestriction">
                  <IPRestrictions />
                </PrivateRoute>
                <Footer />
              </div>
            </div>
          </div>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
