export const tableHeader = [
  { value: "action", key: "security.ipRestriction.action" },
  {
    value: "name",
    key: "security.ipRestriction.name",
  },
  {
    value: "address",
    key: "security.ipRestriction.address",
  },
];
