import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultUser = {
  _id: "",
  username: "",
  password: "",
  confirmedPassword: "",
  name: "",
  role: "user",
  status: "normal",
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultUser,
  },
});

const userReducer = {
  user: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.USER_GET_LIST:
        state = {
          ...state,
          list: action.data,
        };
        return state;
      case ActionTypes.USER_SELECT:
        state = {
          ...state,
          selected: action.data
            ? { ...action.data, password: "", confirmedPassword: "" }
            : { ...defaultUser },
        };
        return state;
      default:
        return state;
    }
  },
};

export default userReducer;
