import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultCarpark = {
  _id: "",
  name: "",
  name_zh: "",
  status: "normal",
  createdBy: "",
  updatedBy: "",
  region: "HK",
  code: "",
  website: "",
  hotline: "",
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultCarpark,
  },
});

const carparkReducer = {
  carpark: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.CARPARK_GET_LIST:
        state = {
          ...state,
          list: action.data,
        };
        return state;
      case ActionTypes.CARPARK_SELECT:
        state = {
          ...state,
          selected: action.data
            ? { ...defaultCarpark, ...action.data }
            : { ...defaultCarpark },
        };
        return state;
      default:
        return state;
    }
  },
};

export default carparkReducer;
