import ActionTypes from "./types";
import activityLogAPIHelper from "../../helpers/api/activityLogAPIHelper";

/**
 * Get list for Vehicle Type
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getList(
  searchFilters,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    activityLogAPIHelper.getList(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.ACTIVITY_LOG_GET_LIST,
          data: {
            list: data,
            totalPages: response.responseDetail.totalPages,
          },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function updateSearchFilters(data) {
  return {
    type: ActionTypes.ACTIVITY_LOG_UPDATE_SEARCH,
    data,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.ACTIVITY_LOG_RESET_SEARCH,
  };
}

const ActivityLogActions = { getList, updateSearchFilters, resetSearchFilters };
export default ActivityLogActions;
