import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import en from "./locale/en";
import zh from "./locale/zh";

const resources = {
    en: { translations: en },
    zh: { translations: zh },
    "zh-hk": { translations: zh }
};

const detectorOptions = {
    // order and from where user language should be detected
    order: ["querystring", "path", "cookie"],

    // keys or params to lookup language from
    lookupQuerystring: "lang",
    lookupCookie: "lang",
    lookupFromPathIndex: 0,

    // cache user language on
    caches: ["cookie"],
    excludeCacheFor: ["cimode"], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: "myDomain",

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // only detect languages that are in the whitelist
    checkWhitelist: true
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(Backend)
    .init({
        resources,
        fallbackLng: "en",
        debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        detection: detectorOptions,
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true,
            useSuspense: false,
        }
    });

export default i18n;