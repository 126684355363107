import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultCampaign = {
  _id: "",
  from: "",
  to: "",
  carpark: "",
  status: "normal",
  name: "",
  parkingFrom: "",
  parkingTo: "",
  purchaseFrom: "",
  purchaseTo: "",
  vehicleLicenseMinimumValidity: 0,
  ballotingDate: "",
  ballotingResultDate: "",
  guidanceNote: {},
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultCampaign,
  },
});

const campaignReducer = {
  campaign: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.CAMPAIGN_GET_LIST:
        state = {
          ...state,
          list: action.data,
        };
        return state;
      case ActionTypes.CAMPAIGN_SELECT:
        state = {
          ...state,
          selected: action.data || { ...defaultCampaign },
        };
        return state;
      default:
        return state;
    }
  },
};

export default campaignReducer;
