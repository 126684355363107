import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const initialState = Immutable({
  loggedIn: true,
  token: "",
  name: "",
  permission: {
    general: [],
    resources: [],
  },
  role: "",
});

const accountReducer = {
  account: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ACCOUNT_LOGIN:
        return {
          ...state,
          token: action.data.token,
          loggedIn: true,
          name: action.data.name,
        };
      case ActionTypes.ACCOUNT_LOGOUT:
        return { ...state, token: "", loggedIn: false, name: "" };
      case ActionTypes.ACCOUNT_GET_PERMISSION:
        return {
          ...state,
          permission: action.data.permission,
          role: action.data.role,
        };
      default:
        return state;
    }
  },
};

export default accountReducer;
