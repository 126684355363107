import React, {Component} from 'react';
import i18next from "i18next";

const Language = (props) => {

    const {style} = props;

    return (
        <li className="onhover-dropdown">
            <a className="txt-dark">
                <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/translate.png")} alt="header-translate" />
            </a>
            <ul className="language-dropdown onhover-show-div p-20" style={{...style}}>
                <li>
                    <a data-lng="en" onClick={() => {
                        if (window.location.href.includes("zh")) {
                            window.location.href = window.location.href.replace("zh", "en")
                        } else {
                            i18next.changeLanguage('en')
                        }
                    }}>
                        <i className="flag-icon flag-icon-us"></i> English
                    </a>
                </li>
                <li>
                    <a data-lng="zh" onClick={() => {
                        if (window.location.href.includes("en")) {
                            window.location.href = window.location.href.replace("en", "zh")
                        } else {
                            i18next.changeLanguage('zh')
                        }
                    }}>
                        <i className="flag-icon flag-icon-hk"></i> 中文
                    </a>
                </li>
            </ul>
        </li>
    );
}

export default Language;
