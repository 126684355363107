import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultAnnouncement = {
  _id: null,
  subject: "",
  subject_zh: "",
  content: "",
  content_zh: "",
  image: "",
  image_zh: "",
  url: "",
  url_zh: "",
  status: "draft",
  createdBy: "",
  updatedBy: "",
  postFrom: "",
  postTo: "",
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultAnnouncement,
  },
});

const announcementReducer = {
  announcement: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ANNOUNCEMENT_GET_LIST:
        state = {
          ...state,
          list: action.data,
        };
        return state;
      case ActionTypes.ANNOUNCEMENT_SELECT:
        state = {
          ...state,
          selected: action.data
            ? { ...defaultAnnouncement, ...action.data }
            : { ...defaultAnnouncement },
        };
        return state;
      default:
        return state;
    }
  },
};

export default announcementReducer;
