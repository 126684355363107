import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { tableHeader } from "../../../constants/ip";
import Portal from "./portal";
import actions, { useActions } from "../../../redux/actions";
import themeColor from "../../../styles/themeColor";

const ActionDropdown = (props) => {
  const ipActions = useActions(actions.IPActions);
  const { t } = useTranslation();
  const { setShowModal, setAlert, ipRestriction, isUpdateAllowed } = props;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="btn btn-sm">{t("option")}</Dropdown.Toggle>
        {isUpdateAllowed ? (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                ipActions.select(ipRestriction);
                setShowModal(true);
              }}
            >
              {t("edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                ipActions.select(ipRestriction);
                setAlert({
                  ...alert,
                  content: t("removeRecord"),
                  show: true,
                  type: "default",
                });
              }}
            >
              {t("remove")}
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                ipActions.select(ipRestriction);
                setShowModal(true);
              }}
            >
              {t("view")}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

const IPRestrictionsList = (props) => {
  const { t } = useTranslation();
  const { ips } = props;
  const [ipRestrictionList, setIpRestrictionList] = useState(ips);
  const account = useSelector((state) => state.account);
  const ip = useSelector((state) => state.ip);
  const ipActions = useActions(actions.IPActions);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isCreateAllowed, setIsCreateAllowed] = useState(false);
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false);

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
    window.location.reload();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  const handleRemove = () => {
    ipActions.remove(
      ip.selected._id,
      account.token,
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("success"),
          content: "",
          type: "success",
        });
      },
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("error"),
          content: "",
          type: "error",
        });
      }
    );
  };

  useEffect(() => {
    setIpRestrictionList(ips);
  }, [ips]);

  useEffect(() => {
    if (
      account.permission.general.includes("ip-create") ||
      account.permission.general.includes("*")
    )
      setIsCreateAllowed(true);
    if (
      account.permission.general.includes("ip-update") ||
      account.permission.general.includes("*")
    )
      setIsUpdateAllowed(true);
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      {isCreateAllowed && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: 20 }}
          onClick={() => {
            ipActions.select(null);
            handleShowModal();
          }}
        >
          {t("add")}
        </button>
      )}
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={ip.selected._id ? handleRemove : handleCloseAlert}
        onCancel={handleCloseAlert}
        showCancel={ip.selected._id}
        cancelBtnCssClass="btn btn-light"
        cancelBtnStyle={{ color: themeColor.black }}
        confirmBtnText={t("ok")}
        cancelBtnText={t("cancel")}
      >
        {alert.content}
      </SweetAlert>
      <Portal
        show={showModal}
        values={ip.selected}
        readOnly={!isUpdateAllowed}
      />
      <Table>
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                {t(item.key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ipRestrictionList.map((ipRestriction) => {
            return (
              <tr>
                <td>
                  <ActionDropdown
                    ipRestriction={ipRestriction}
                    setShowModal={setShowModal}
                    setAlert={setAlert}
                    isUpdateAllowed={isUpdateAllowed}
                  />
                </td>
                <td>{ipRestriction.name}</td>
                <td>{ipRestriction.address}</td>
              </tr>
            );
          })}
          {ipRestrictionList.length < 1 ? (
            <tr>
              <td colSpan={tableHeader.length} style={{ textAlign: "center" }}>
                {t("noResults")}
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
};

export default IPRestrictionsList;
