export const tableHeader = [
  {
    value: "action",
    key: "profile.campaigns.action",
  },
  {
    value: "campaign id",
    key: "profile.campaigns.campaignId",
  },
  {
    value: "name",
    key: "profile.campaigns.name",
  },
  {
    value: "period",
    key: "profile.campaigns.period",
  },
  {
    value: "parking",
    key: "profile.campaigns.parking",
  },
  {
    value: "carpark id",
    key: "profile.campaigns.carparkId",
  },
  {
    value: "carpark",
    key: "profile.campaigns.carpark",
  },
  {
    value: "status",
    key: "profile.campaigns.status",
  },
];

export const status = [
  {
    value: "normal",
    key: "profile.campaigns.normal",
  },
  {
    value: "suspend",
    key: "profile.campaigns.suspend",
  },
];
