import baseAPIHelper from "./baseAPIHelper";

function exportReport(searchFilters, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/exportreport?access_token=${token}`;
  baseAPIHelper("POST", url, searchFilters, successCallback, failCallback);
}

function getReportProgress(reportId, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/exportreport/progress/${reportId}?access_token=${token}`;
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const reportAPIHelper = {
  exportReport,
  getReportProgress,
};

export default reportAPIHelper;
