import ActionTypes from "./types";
import campaignAPIHelper from "../../helpers/api/campaignAPIHelper";

/**
 * Get list for Campaign
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getList(
  searchFilters,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    campaignAPIHelper.getList(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.CAMPAIGN_GET_LIST,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Get list for Active Campaign
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getActiveList(
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    campaignAPIHelper.getActiveList(
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.CAMPAIGN_GET_ACTIVE_LIST,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Select the campaign for form input
 * @param {Object} data The data of selected campaign
 * @returns {{data: *, type: string}} Redux Action
 */
export function select(data) {
  return {
    type: ActionTypes.CAMPAIGN_SELECT,
    data,
  };
}

/**
 * Create new campaign
 * @param {Object} data Data for creating campaign
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function create(data, token, successCallback, failCallback) {
  return (dispatch) => {
    campaignAPIHelper.create(
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CAMPAIGN_SELECT,
          data: null, // Reset selected campaign
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Update existing campaign
 * @param {String} id ID of the campaign to be updated
 * @param {Object} data Data for updating campaign
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function update(id, data, token, successCallback, failCallback) {
  return (dispatch) => {
    campaignAPIHelper.update(
      id,
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CAMPAIGN_SELECT,
          data: null, // Reset selected campaign
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function remove(id, token, successCallback, failCallback) {
  return (dispatch) => {
    campaignAPIHelper.remove(
      id,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CAMPAIGN_SELECT,
          data: null, // Reset selected campaign
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        dispatch({
          type: ActionTypes.CAMPAIGN_SELECT,
          data: null, // Reset selected campaign
        });
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const CampaignActions = {
  getList,
  getActiveList,
  select,
  create,
  update,
  remove,
};
export default CampaignActions;
