import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import FormHeader from "../../../components/formHeader";
import { status } from "../../../constants/vehicles";
import generalAPIHelper from "../../../helpers/api/generalAPIHelper";
import actions, { useActions } from "../../../redux/actions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const vehicleTypeActions = useActions(actions.VehicleTypeActions);
  const accountActions = useActions(actions.AccountActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }

    setValidated(true);
    if (!formValues._id) {
      vehicleTypeActions.create(
        formValues,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    } else {
      vehicleTypeActions.update(
        formValues._id,
        formValues,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
  };
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const handleUpload = (e) => {
    // const icon = e.target.files[0];
    // const url = icon ? URL.createObjectURL(icon) : "";
    // setFormValues({ ...formValues, icon: url });
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    generalAPIHelper.uploadAttachment(
      account.token,
      formData,
      (response) => {
        setFormValues({
          ...formValues,
          icon: {
            _id: response.responseDetail._id,
            fileUrl: response.responseDetail.fileUrl,
          },
        });
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
      }
    );
  };

  const handleRemove = () => {
    setFormValues({
      ...formValues,
      icon: {
        _id: "",
        fileUrl: "",
      },
    });
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.vehicles.name")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name"
                  type="text"
                  value={formValues.name}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.vehicles.name")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name_zh"
                  type="text"
                  value={formValues.name_zh}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.vehicles.code")}
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  required
                  name="code"
                  type="text"
                  value={formValues.code}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.vehicles.identifier")}
              </Form.Label>
              <Col sm="5">
                <Form.Control
                  required
                  name="identifier"
                  type="text"
                  value={formValues.identifier}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.vehicles.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.status === s.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.vehicles.icon")}
              </Form.Label>
              <Col sm="8">
                {!formValues.icon || !formValues.icon._id ? (
                  <label className="btn btn-primary">
                    {t("profile.vehicles.upload")}
                    <Form.Control
                      name="icon"
                      type="file"
                      onChange={handleUpload}
                      style={{ display: "none" }}
                      disabled={readOnly}
                    />
                  </label>
                ) : (
                  <button
                    name="image_zh"
                    className="btn btn-primary"
                    type="button"
                    onClick={handleRemove}
                    disabled={readOnly}
                  >
                    {t("profile.vehicles.remove")}
                  </button>
                )}
              </Col>
              <Col sm="3" />
              <Col sm="8">
                <img
                  alt=""
                  src={formValues.icon ? formValues.icon.fileUrl : ""}
                  style={{
                    maxWidth: 200,
                    objectFit: "contain",
                    marginTop: 20,
                  }}
                />
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
      <SpinnerOverlay loading={isLoading} />
    </Modal>
  );
}

export default Portal;
