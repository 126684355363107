import baseAPIHelper from "./baseAPIHelper";

function getSettings(token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/setting?access_token=${token}`;
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function update(data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/setting/update?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

const settingAPIHelper = {
  getSettings,
  update,
};

export default settingAPIHelper;
