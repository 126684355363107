import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import actions, { useActions } from "../../../redux/actions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";
import generalAPIHelper from "../../../helpers/api/generalAPIHelper";
import themeColor from "../../../styles/themeColor";

const Settings = (props) => {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const settingActions = useActions(actions.SettingActions);
  const accountActions = useActions(actions.AccountActions);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    notificationEmail: "",
    exportEmail: "",
    passwordEmail: "",
    tdWebsite: "",
    tdWebsite_zh: "",
    personalInformationCollectionStatement: "",
  });

  const handleSaveSettings = () => {
    setIsLoading(true);
    const data = {
      ...settings,
      personalInformationCollectionStatement:
        settings.personalInformationCollectionStatement._id,
    };
    settingActions.update(
      data,
      token,
      () => {
        window.location.reload();
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
      }
    );
  };

  const onValueChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleUpload = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    generalAPIHelper.uploadAttachment(
      token,
      formData,
      (response) => {
        setSettings({
          ...settings,
          personalInformationCollectionStatement: {
            _id: response.responseDetail._id,
            fileUrl: response.responseDetail.fileUrl,
          },
        });
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
      }
    );
  };

  const handleRemove = () => {
    setSettings({
      ...settings,
      personalInformationCollectionStatement: {
        _id: "",
        fileUrl: "",
      },
    });
  };

  useEffect(() => {
    settingActions.getSettings(token, (response) => {
      setSettings(response);
    });
  }, []);

  return (
    <div className="setting-container" style={{ minHeight: "60vh" }}>
      <div className="setting-row">
        <span className="setting-tag">
          {t("profile.setting.notificationEmail")}
        </span>
        <input
          type="email"
          className="setting-input"
          value={settings.notificationEmail}
          name="notificationEmail"
          onChange={onValueChange}
        />
      </div>

      <div className="setting-row">
        <span className="setting-tag">{t("profile.setting.exportEmail")}</span>
        <input
          type="email"
          className="setting-input"
          value={settings.exportEmail}
          name="exportEmail"
          onChange={onValueChange}
        />
      </div>

      <div className="setting-row">
        <span className="setting-tag">
          {t("profile.setting.passwordEmail")}
        </span>
        <input
          type="email"
          className="setting-input"
          value={settings.passwordEmail}
          name="passwordEmail"
          onChange={onValueChange}
        />
      </div>

      <div className="setting-row">
        <span className="setting-tag">{t("profile.setting.tdWebsite")}</span>
        <input
          type="text"
          className="setting-input"
          value={settings.tdWebsite}
          name="tdWebsite"
          onChange={onValueChange}
        />
      </div>

      <div className="setting-row">
        <span className="setting-tag">{t("profile.setting.tdWebsiteZH")}</span>
        <input
          type="text"
          className="setting-input"
          value={settings.tdWebsite_zh}
          name="tdWebsite_zh"
          onChange={onValueChange}
        />
      </div>

      <div className="setting-row">
        <span className="setting-tag">
          {t("profile.setting.personalInformationCollectionStatement")}
        </span>
        {settings.personalInformationCollectionStatement.fileUrl && (
          <a
            href={settings.personalInformationCollectionStatement.fileUrl}
            target="_blank"
            style={{ color: themeColor.blueGreen }}
          >
            <img
              alt={t("profile.setting.personalInformationCollectionStatement")}
              src={settings.personalInformationCollectionStatement.fileUrl}
              style={{
                maxHeight: 250,
                objectFit: "contain",
                marginRight: 20,
              }}
            />
          </a>
        )}
        {!settings.personalInformationCollectionStatement.fileUrl ? (
          <label className="btn btn-primary" style={{ alignSelf: "flex-end" }}>
            {t("profile.setting.upload")}
            <input
              name="personalInformationCollectionStatement"
              type="file"
              onChange={handleUpload}
              style={{ display: "none" }}
            />
          </label>
        ) : (
          <button className="btn btn-primary" onClick={() => handleRemove()}>
            {t("profile.setting.remove")}
          </button>
        )}
      </div>

      <button
        type="button"
        className="btn btn-primary setting-btn"
        onClick={handleSaveSettings}
      >
        {t("save")}
      </button>
      <SpinnerOverlay loading={isLoading} />
    </div>
  );
};

export default Settings;
