import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";
import {reportTypeEnum} from "../../constants/exportreport";

const defaultSearchFilters = {
  createdAt: {
    from: "",
    to: "",
  },
  campaign: "",
  carpark: "",
  type: "",
  date: "",
  applicationType: ""
};

const initialState = Immutable({
  searchFilters: {
    ...defaultSearchFilters,
  },
});

const exportReportReducer = {
  exportreport: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.EXPORT_REPORT_UPDATE_SEARCH:
        return {
          ...state,
          searchFilters: {
            ...state.searchFilters,
            ...action.data,
          },
        };
      case ActionTypes.EXPORT_REPORT_UPDATE_REPORT_TYPE:
        return {
          ...state,
          searchFilters: {
            ...defaultSearchFilters,
            type: action.data.type,
            applicationType: action.data.type === reportTypeEnum.CHECK_LIST_FILE ? 'online' : ''
          },
        };
      case ActionTypes.EXPORT_REPORT_RESET_SEARCH:
        return {
          ...state,
          searchFilters: defaultSearchFilters,
        };
      case ActionTypes.EXPORT_REPORT_GENERATE_REPORT:
        return {
          ...state,
          reportId: action.data.id,
        };
      default:
        return state;
    }
  },
};

export default exportReportReducer;
