import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { tableHeader } from "../../../constants/announcements";
import actions, { useActions } from "../../../redux/actions";
import Portal from "./portal";
import themeColor from "../../../styles/themeColor";

const ActionDropdown = (props) => {
  const announcementActions = useActions(actions.AnnouncementActions);
  const { t } = useTranslation();
  const { setShowModal, setAlert, announcement, isUpdateAllowed } = props;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="btn btn-sm">{t("option")}</Dropdown.Toggle>
        {isUpdateAllowed ? (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                announcementActions.select(announcement);
                setShowModal(true);
              }}
            >
              {t("edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                announcementActions.select(announcement);
                setAlert({
                  ...alert,
                  content: t("removeRecord"),
                  show: true,
                  type: "default",
                });
              }}
            >
              {t("remove")}
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                announcementActions.select(announcement);
                setShowModal(true);
              }}
            >
              {t("view")}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

const AnnouncementList = (props) => {
  const { t, i18n } = useTranslation();
  const { announcements } = props;
  const announcement = useSelector((state) => state.announcement);
  const account = useSelector((state) => state.account);
  const announcementActions = useActions(actions.AnnouncementActions);
  const accountActions = useActions(actions.AccountActions);
  const [announcementList, setAnnouncementList] = useState(announcements);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isCreateAllowed, setIsCreateAllowed] = useState(false);
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false);

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
    window.location.reload();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    announcementActions.remove(
      announcement.selected._id,
      account.token,
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("success"),
          content: "",
          type: "success",
        });
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else {
          setAlert({
            ...alert,
            show: true,
            title: t("error"),
            content: "",
            type: "error",
          });
        }
      }
    );
  };

  useEffect(() => {
    setAnnouncementList(announcements);
  }, [announcements]);

  useEffect(() => {
    if (
      account.permission.general.includes("announcement-create") ||
      account.permission.general.includes("*")
    )
      setIsCreateAllowed(true);
    if (
      account.permission.general.includes("announcement-update") ||
      account.permission.general.includes("*")
    )
      setIsUpdateAllowed(true);
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      {isCreateAllowed && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: 20 }}
          onClick={() => {
            announcementActions.select(null);
            handleShowModal();
          }}
        >
          {t("add")}
        </button>
      )}
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={announcement.selected._id ? handleRemove : handleCloseAlert}
        onCancel={handleCloseAlert}
        showCancel={announcement.selected._id}
        cancelBtnCssClass="btn btn-light"
        cancelBtnStyle={{ color: themeColor.black }}
        confirmBtnText={t("ok")}
        cancelBtnText={t("cancel")}
      >
        {alert.content}
      </SweetAlert>
      <Portal
        show={showModal}
        values={announcement.selected}
        readOnly={!isUpdateAllowed}
      />
      <Table>
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                {t(item.key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {announcementList.map((announcementItem) => {
            return (
              <tr>
                <td>
                  <ActionDropdown
                    announcement={announcementItem}
                    setShowModal={setShowModal}
                    setAlert={setAlert}
                    isUpdateAllowed={isUpdateAllowed}
                  />
                </td>
                <td style={{ minWidth: 150 }}>
                  <tr>{`(${t("en")}) ${announcementItem.subject}`}</tr>
                  <tr>{`(${t("zh")}) ${announcementItem.subject_zh}`}</tr>
                </td>
                <td>
                  <tr>
                    {`(${t("en")}) `}
                    <img
                      src={
                        announcementItem.image
                          ? announcementItem.image.fileUrl
                          : ""
                      }
                      style={{
                        maxWidth: "15vw",
                        maxHeight: "20vh",
                        objectFit: "contain",
                        marginBottom: 20,
                      }}
                      alt=""
                    />
                  </tr>
                  <tr>
                    {`(${t("zh")}) `}
                    <img
                      src={
                        announcementItem.image_zh
                          ? announcementItem.image_zh.fileUrl
                          : ""
                      }
                      style={{
                        maxWidth: "15vw",
                        maxHeight: "20vh",
                        objectFit: "contain",
                      }}
                      alt=""
                    />
                  </tr>
                </td>
                <td
                  style={{
                    textOverflow: "ellipsis",
                    minWidth: 150,
                    overflow: "hidden",
                    wordBreak: "break-word",
                  }}
                >
                  <tr>
                    {`(${t("en")}) `}
                    <a
                      href={announcementItem.url}
                      target="_blank"
                      style={{ color: themeColor.blueGreen }}
                    >
                      {announcementItem.url}
                    </a>
                  </tr>
                  <tr>
                    {`(${t("zh")}) `}
                    <a
                      href={announcementItem.url_zh}
                      target="_blank"
                      style={{ color: themeColor.blueGreen }}
                    >
                      {announcementItem.url_zh}
                    </a>
                  </tr>
                </td>
                <td>{t(`profile.news.${announcementItem.status}`)}</td>
                <td>
                  {moment(announcementItem.createdAt).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                </td>
              </tr>
            );
          })}
          {announcementList.length < 1 ? (
            <tr>
              <td colSpan={tableHeader.length} style={{ textAlign: "center" }}>
                {t("noResults")}
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
};

export default AnnouncementList;
