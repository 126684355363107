import React, { Component } from "react";
import { useSelector } from "react-redux";
import i18n from "../../../i18n/i18n";
import actions, { useActions } from "../../../redux/actions";

const UserMenu = (props) => {
  const accountActions = useActions(actions.AccountActions);
  const account = useSelector((state) => state.account);
  const logOut = (e) => {
    e.preventDefault();
    accountActions.logout();
    window.location = `/${i18n.language}`;
  };

  return (
    <li className="onhover-dropdown">
      <div className="media  align-items-center">
        <img
          className="align-self-center pull-right mr-2"
          src={require("../../../assets/images/dashboard/user.png")}
          alt="header-user"
        />
        <div className="media-body">
          <h6 className="m-0 txt-dark f-16">
            {account.name || ""}
            <i className="fa fa-angle-down pull-right ml-2" />
          </h6>
        </div>
      </div>
      <ul className="profile-dropdown onhover-show-div p-20">
        <li>
          <a href="#" onClick={logOut}>
            <i className="icon-power-off" />
            {i18n.t("logout")}
          </a>
        </li>
      </ul>
    </li>
  );
};

export default UserMenu;
