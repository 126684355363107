import ActionTypes from "./types";
import authAPIHelper from "../../helpers/api/authAPIHelper";

/**
 * User Login API Call
 * @param {String} username Login username
 * @param {String} password Login password
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function login(username, password, successCallback, failCallback) {
  return (dispatch) => {
    authAPIHelper.login(
      username,
      password,
      (response) => {
        const {
          responseDetail: { token, username: name },
        } = response;
        dispatch({
          type: ActionTypes.ACCOUNT_LOGIN,
          data: { token, name },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * User Logout
 * @returns {{type: string}} Redux Action
 */
export function logout() {
  return {
    type: ActionTypes.ACCOUNT_LOGOUT,
  };
}

export function getPermission(
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    authAPIHelper.getPermission(
      token,
      (response) => {
        const {
          responseDetail: { permission, role },
        } = response;
        dispatch({
          type: ActionTypes.ACCOUNT_GET_PERMISSION,
          data: { permission, role },
        });
        if (typeof successCallback === "function") successCallback(permission, role);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const AccountActions = { login, logout, getPermission };
export default AccountActions;
