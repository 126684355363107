import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import AccountActions from "./account";
import CarparkActions from "./carpark";
import AnnouncementActions from "./announcement";
import CampaignActions from "./campaign";
import ApplicationActions from "./application";
import VehicleTypeActions from "./vehicletype";
import UserActions from "./user";
import ActivityLogActions from "./activitylog";
import ReportActions from "./report";
import SettingActions from "./setting";
import IPActions from "./ip";
import ExportReportAction from "./exportreport";

export const ActionCreators = {
  AccountActions,
  CarparkActions,
  AnnouncementActions,
  CampaignActions,
  ApplicationActions,
  VehicleTypeActions,
  UserActions,
  ActivityLogActions,
  ReportActions,
  ExportReportAction,
  SettingActions,
  IPActions,
};

export function useActions(actions, deps) {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map((a) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    deps ? [dispatch, ...deps] : [dispatch]
  );
}

export default ActionCreators;
