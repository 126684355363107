export const reportTypeEnum = {
  MOTOR_VEHICLE_LICENSE: "motor_vehicle_license",
  VEHICLE_LICENSE_FILE: "vehicle_license_file",
  CHECK_LIST_FILE: "check_list_file",
  APPLICATION_REJECT: "application_reject",
  APPLICATION_REMARK: "application_remark",
};

export const reportFieldsEnum = {
  CAMPAIGN: "campaign",
  CARPARK: "carpark",
  DATE: "date",
  FROM: "from",
  TO: "to",
  APPLICATION_TYPE: "applicationType",
};
export const reportTypes = [
  // {
  //   value: reportTypeEnum.MOTOR_VEHICLE_LICENSE,
  //   key: "profile.exportReports.motorVehicleLicense",
  //   fields: [
  //     {
  //       field: reportFieldsEnum.CAMPAIGN,
  //       preRequiredFields: [reportFieldsEnum.CARPARK],
  //     },
  //     reportFieldsEnum.CARPARK,
  //     reportFieldsEnum.FROM,
  //     reportFieldsEnum.TO,
  //   ],
  //   requiredFields: [
  //     reportFieldsEnum.CAMPAIGN,
  //     reportFieldsEnum.FROM,
  //     reportFieldsEnum.TO,
  //   ],
  // },
  // {
  //   value: reportTypeEnum.VEHICLE_LICENSE_FILE,
  //   key: "profile.exportReports.vehicleLicenseFile",
  //   fields: [
  //     reportFieldsEnum.CAMPAIGN,
  //     reportFieldsEnum.FROM,
  //     reportFieldsEnum.TO,
  //   ],
  //   requiredFields: [reportFieldsEnum.CAMPAIGN],
  // },
  {
    value: reportTypeEnum.CHECK_LIST_FILE,
    key: "profile.exportReports.checkListFile",
    fields: [
      reportFieldsEnum.CAMPAIGN,
      reportFieldsEnum.DATE,
      reportFieldsEnum.APPLICATION_TYPE,
    ],
    requiredFields: [
      reportFieldsEnum.CAMPAIGN,
      reportFieldsEnum.DATE,
      reportFieldsEnum.APPLICATION_TYPE,
    ],
    fieldsOffline: [
      reportFieldsEnum.CAMPAIGN,
      reportFieldsEnum.FROM,
      reportFieldsEnum.TO,
      reportFieldsEnum.APPLICATION_TYPE,
    ],
    requiredFieldsOffline: [
      reportFieldsEnum.CAMPAIGN,
      reportFieldsEnum.FROM,
      reportFieldsEnum.TO,
      reportFieldsEnum.APPLICATION_TYPE,
    ],
    maxDays: 30,
  },
  {
    value: reportTypeEnum.APPLICATION_REJECT,
    key: "profile.exportReports.applicationReject",
    fields: [reportFieldsEnum.FROM, reportFieldsEnum.TO],
    requiredFields: [reportFieldsEnum.FROM, reportFieldsEnum.TO],
    maxDays: 30,
  },
  {
    value: reportTypeEnum.APPLICATION_REMARK,
    key: "profile.exportReports.applicationRemark",
    fields: [reportFieldsEnum.FROM, reportFieldsEnum.TO],
    requiredFields: [reportFieldsEnum.FROM, reportFieldsEnum.TO],
    maxDays: 30,
  },
];

export const reportStatus = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  SUCCESSFUL: "successful",
  ERROR: "error",
};
