import React from "react";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import reducer from "../reducers";

const loggerMiddleware = createLogger({});

const enhancer = compose(
    applyMiddleware(
        thunk, // lets us dispatch() functions
        loggerMiddleware
    )
);

export default function configureStore(initialState) {
    return createStore(reducer, initialState, enhancer);
}
