export default {
  logout: "登出",
  subTitle: "網上抽籤系統 CMS",
  title: "運輸署多層停車場 - 透過互聯網提交泊車季票申請",
  save: "儲存",
  cancel: "取消",
  edit: "編緝",
  remove: "移除",
  option: "選項",
  add: "新增",
  en: "英",
  zh: "中",
  noResults: "沒有結果",
  success: "成功",
  error: "錯誤",
  missingField: "請填寫此欄",
  missingFile: "請選擇檔案",
  ok: "確認",
  removeRecord: "你確定要移除此紀錄嗎？",
  view: "詳細內容",
  general: {
    title: "一般",
    home: {
      title: "主頁",
      welcome: "歡迎",
    },
  },
  profile: {
    title: "檔案",
    carparks: {
      title: "停車場",
      action: "動作",
      no: "編號",
      name: "名稱",
      status: "狀態",
      normal: "正常",
      suspend: "暫停使用",
      region: "地區",
      hk: "香港",
      kln: "九龍",
      nt: "新界",
      code: "停車場代號",
      website: "網頁",
      hotline: "熱線",
    },
    vehicles: {
      title: "汽車",
      action: "動作",
      code: "編號",
      name: "名稱",
      identifier: "識別碼",
      status: "狀態",
      normal: "正常",
      suspend: "暫停使用",
      icon: "圖示",
      upload: "上載",
      remove: "移除",
    },
    staff: {
      title: "職員",
      action: "動作",
      username: "用戶名稱",
      name: "姓名",
      status: "狀態",
      normal: "可用",
      suspend: "不可用",
      role: "角色",
      user: "停車場職員",
      staff: "職員",
      manager: "經理",
      admin: "管理員",
      application: "Application",
      password: "密碼",
      confirmPassword: "確認密碼",
      pwdNotMatch: "密碼不相符",
    },
    news: {
      title: "通告",
      action: "動作",
      content: "內容",
      image: "圖片",
      url: "連結",
      status: "狀態",
      publish: "顯示",
      draft: "隱藏",
      hide: "隱藏",
      subject: "標題",
      upload: "上載",
      remove: "移除",
      createTime: "創建時間",
      postPeriod: "刊登時段",
      from: "由",
      to: "至",
    },
    campaigns: {
      title: "活動",
      action: "動作",
      period: "活動時段",
      carparks: "停車場",
      carpark: "停車場",
      status: "狀態",
      normal: "有效",
      suspend: "無效",
      from: "由",
      to: "至",
      name: "名稱",
      parking: "泊車時段",
      purchasePeriod: "購買季票期限",
      vehicleLicenseMinimumValidity: "車輛牌照有效期要求（天數）",
      campaignId: "活動編號",
      carparkId: "停車場編號",
      ballotingDate: "抽籤日期",
      ballotingResultDate: "結果公佈日期",
      guidanceNote: "申請泊車季票說明書",
      upload: "上載",
      remove: "移除",
    },
    applications: {
      title: "申請",
      rejectedTitle: "拒絕申請",
      action: "動作",
      applicationNumber: "申請編號",
      applicantName: "申請者名稱",
      phone: "電話",
      email: "電郵",
      vehicleRegistrationMark: "車輛牌照號碼",
      vehicleLicenseExpireDate: "車輛牌照到期日",
      vehicleLicenseCopy: "車輛牌照副本",
      physicalDisability: "殘疾駕駛人士",
      yes: "是",
      no: "否",
      disablePersonParkingPermit: "傷殘人士泊車許可證",
      type: "申請類別",
      online: "線上",
      offline: "書面",
      vehicleType: "車輛類別",
      remark: "備註",
      status: "申請狀態",
      pending: "待審核",
      rejected: "已拒絕",
      approved: "已批準",
      staffRemark: "職員備註",
      staffRemarkDetail: "詳細職員備註",
      rejectedBy: "拒絕者",
      rejectedAt: "拒絕日期",
      approvedBy: "批準者",
      approvedAt: "批準日期",
      campaign: "活動",
      carpark: "停車場",
      search: "搜尋",
      notSelected: "未選擇",
      all: "所有",
      sortBy: "排序方式：",
      createdDate: "創建時間",
      order: "排列次序：",
      rejectReason: "拒絕原因",
      applicationHardCopy: "申請表副本",
      pendingReason: "待審核原因",
      vehicleLicenseOcrFailed: "車輛牌照 - 辨識失敗",
      vehicleLicenseOcrNotMatch: "車輛牌照 - 資料不符",
      disablePersonParkingPermitOcrFailed: "傷殘人士泊車許可證 - 辨識失敗",
      disablePersonParkingPermitOcrNotMatch: "傷殘人士泊車許可證 - 資料不符",
      remarkIsNotEmpty: "申請人填寫了備注",
      // incorrectVehicleRegistrationMark: "錯誤車輛登記號碼",
      // incorrectLicenceExpires: "錯誤牌照屆滿日期",
      incorrectVehicleRegistrationMark: "Incorrect Vehicle Registration Mark",
      incorrectLicenceExpires: "Incorrect Licence Expires",
      // other: "其他(請說明)",
      other: "Other(Please Specify)",
      rejectReasonDetail: "詳細拒絕原因",
      resend: "重發確認電郵",
      download_copy: "下載副本",
      dateRange: "創建日期範圍",
      from: "由",
      to: "至",
      reset: "重設",
      // disabledPermitProblem: "殘疾人許可問題",
      incorrectVehicleLicenseInformation: "車輛牌照信息不正確",
      disabledPermitProblem: "Disabled Permit problem",
      // incorrectVehicleLicenseInformation:
      //     "Incorrect Vehicle License Information",
      // noLicenceSupply: "沒有許可證供應",
      noLicenceSubmit: "No Licence Submit",
      vehicleTypeTricycle: "Vehicle Type (Tricycle)",
      incompleteOrBlurryPhoto: "The photo is Incomplete or Blurry",
      vehicleTypeNotMatch: "Vehicle Type not match",
      cantOpenThePhoto: "Cannot open the photo",
      // vehicleTypeTricycle: "車輛類型（三輪車",
      // vehicleTypeNotMatch: "車輛類型不匹配",
      invalidVehicleRegistrationDocument: "無效的車輛登記文件",
      vehicleLicenseExpireDateRange: "車牌過期日期範圍",
      // approveRemark: {
      //   notExpiredOnTheDate: "申請表尚未過期",
      //   theLicenceDateIsNotMatch: "牌照日期不正確，但牌照有效",
      //   theLicenceDateIsNotMatchNNotExpired:
      //     "牌照日期不正確，但牌照有效及未過期",
      //   applicationFormInCarpark: "員工忘記上傳申請表",
      //   VehicleLicenceInCarpark: "員工忘記上傳車牌牌照",
      // },
      // rejectRemark: {
      //   leaveMessage: "已留言",
      //   applicantHasBeenNoticed: "已通知申請人, 重新申請",
      //   cantReachTheClient: "多次連络，均未能接通，無法留言",
      // },
      approveRemark: {
        notExpiredOnTheDate: "Not expired on the date of application",
        theLicenceDateIsNotMatch:
          "The Licence Date is not match. But the Licence is valid",
        theLicenceDateIsNotMatchNNotExpired:
          "The Licence Date is not match. But the Licence is valid. And Not expired on the date of application",
        applicationFormInCarpark:
          "Application form in Carpark (Checked). Staff forgot upload",
        VehicleLicenceInCarpark:
          "Vehicle Licence in Carpark (Checked). Staff forgot upload",
      },
      rejectRemark: {
        incorrectInputByApplicant: "Incorrect input by Applicant",
        incorrectInputByStaff: "Incorrect input by Staff",
        applicantHasBeenContacted:
          "The applicant has been contacted and will reapply",
        LeftMessage: "Left a Message",
        cantReachTheClient:
          "Contacted many times but failed to connect, unable to leave a message",
      },
    },
    applicationGenerator: {
      title: "申請表產生器",
      carpark: "停車場：",
      vehicleType: "車輛類別：",
      generate: "產生",
      campaign: "活動：",
      noRunningCampaign: "此停車場未有任何活動",
      noCarpark: "沒有停車場",
      noVehicleType: "沒有車輛類別",
      notSelected: "未選擇",
    },
    applicationExport: {
      title: "申請表匯出",
      send: "電郵申請人清單",
      campaign: "活動：",
      success: "已成功發送",
      error: "未能發送，請重試。",
      download: "下載申請人清單",
    },
    reports: {
      title: "報表",
      dailyCount: "每日報表",
      manual: "人手處理",
      exception: "異常個案",
      date: "日期",
      online: "網上申請",
      offline: "書面申請",
      dateRange: "日期範圍",
    },
    exportReports: {
      title: "匯出報表",
      dateRange: "日期範圍",
      campaign: "活動",
      notSelected: "未選擇",
      carpark: "停車場",
      from: "由",
      to: "至",
      export: "匯出",
      reset: "重設",
      reportType: "報表類別",
      motorVehicleLicense: "Motor Vehicle License",
      vehicleLicenseFile: "Vehicle License File",
      checkListFile: "Check List File",
      applicationReject: "Reject report",
      applicationRemark: "Remark report",
      date: "日期",
      applicationType: {
        label: "報名類型",
        online: "線上",
        offline: "書面",
      },
      maxDays: "(最多{{days}}天)",
      downloadFailed: "下載報告失敗",
      downloadStarted: "已開始下載報告",
      form: {
        campaignIsRequired: "活動不能為空",
        carparkIsRequired: "停車場不能為空",
        reportTypeIsRequired: "報表類別不能為空",
        createdAtFromIsRequired: "日期範圍(由)不能為空",
        createdAtToIsRequired: "日期範圍(至)不能為空",
        createdAtDateIsRequired: "日期不能為空",
        createdAtOutOfRange: "日期範圍不正確",
      },
    },
    activityLog: {
      title: "活動紀錄",
      event: "活動",
      createdBy: "創建用戶",
      createdDate: "創建時間",
      announcementCreate: "創建通告",
      announcementUpdate: "更新通告",
      announcementDelete: "刪除通告",
      applicationCreate: "創建申請",
      applicationConfirm: "批准申請",
      applicationReject: "拒絕申請",
      applicationGenerate: "產生列印用試算表",
      applicationExport: "匯出申請表",
      campaignCreate: "創建活動",
      campaignUpdate: "更新活動",
      carparkCreate: "創建停車場",
      carparkUpdate: "更新停車場",
      carparkDelete: "刪除停車場",
      userCreate: "創建用戶",
      userUpdate: "更新用戶",
      userDelete: "刪除用戶",
      userLogin: "用戶登入",
      vehicleTypeCreate: "創建車輛類型",
      vehicleTypeUpdate: "更新車輛類型",
      vehicleTypeDelete: "刪除車輛類型",
      settingUpdate: "更新設定",
      ipCreate: "創建IP限制",
      ipUpdate: "更新IP限制",
      ipDelete: "刪除IP限制",
    },
    setting: {
      title: "設定",
      notificationEmail: "泊車季票申請電郵",
      exportEmail: "匯出 (已批準的申請列表) 電郵",
      passwordEmail: "試算表密碼電郵",
      tdWebsite: "運輸署英文網站",
      tdWebsiteZH: "運輸署中文網站",
      guidanceNote: "申請泊車季票說明書",
      upload: "上載",
      personalInformationCollectionStatement: "個人資料收集聲明",
      remove: "移除",
    },
  },
  login: {
    login: "登入",
    remarks: "輸入用戶名稱及密碼以登入",
    username: "用戶名稱",
    password: "密碼",
    success: "成功登入",
    error: "登入錯誤",
    unauthorized: "禁止進入：此IP地址已被拒絕。",
  },
  security: {
    title: "安全",
    ipRestriction: {
      title: "IP限制",
      action: "動作",
      name: "名稱",
      address: "地址",
    },
  },
};
