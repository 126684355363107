function baseAPIHelper(
  method,
  url,
  data,
  successCallback,
  failCallback,
  headers = null,
  upload = false
) {
  if (!headers)
    headers = {
      Accept: "application/json, text/plain, /",
      "Content-Type": "application/json",
    };
  let isFailed = false;

  const options = { method, headers };
  if (data && !upload) options.body = JSON.stringify(data);

  if (upload) options.body = data;
  fetch(url, options)
    .then((res) => {
      if (res.status === 200 || res.status === 202) {
        return res.json();
      }
      isFailed = true;
      return res.json();
    })
    .then((response) => {
      if (isFailed) throw response;
      if (typeof successCallback === "function") successCallback(response);
    })
    .catch((error) => {
      if (typeof failCallback === "function") failCallback(error);
    });
}

export default baseAPIHelper;
