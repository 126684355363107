import baseAPIHelper from "./baseAPIHelper";

function uploadAttachment(token, data, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/attachment/upload?`;
  if (token) url += `access_token=${token}`;
  baseAPIHelper(
    "POST",
    url,
    data,
    successCallback,
    failCallback,
    new Headers({}),
    true
  );
}

const generalAPIHelper = {
  uploadAttachment,
};

export default generalAPIHelper;
