import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import SearchBox from "./searchBox";

const ExportReport = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Breadcrumb
        title={t("profile.exportReports.title")}
        parent={t("profile.title")}
      />
      <div className="container-fluid">
        <SearchBox />
      </div>
    </div>
  );
};

export default withRouter(ExportReport);
