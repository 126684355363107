export default {
  logout: "Logout",
  subTitle: "Online Ballot System CMS",
  title:
    "Transport Department's Multi-storey Car Parks - Online Application for Quarterly Parking Ticket",
  save: "Save",
  cancel: "Cancel",
  edit: "Edit",
  remove: "Remove",
  option: "Options",
  add: "Add",
  en: "EN",
  zh: "ZH",
  noResults: "No Results Found",
  success: "Success",
  error: "Error",
  missingField: "Please enter this field",
  missingFile: "Please select a file",
  ok: "Confirm",
  removeRecord: "Are you sure to remove this record?",
  view: "View Details",
  general: {
    title: "General",
    home: {
      title: "HOME",
      welcome: "Welcome",
    },
  },
  profile: {
    title: "Profile",
    carparks: {
      title: "Carparks",
      action: "Action",
      no: "Number",
      name: "Name",
      status: "Status",
      normal: "Normal",
      suspend: "Suspend",
      region: "Region",
      hk: "Hong Kong",
      kln: "Kowloon",
      nt: "New Territories",
      code: "Carpark Code",
      website: "Website",
      hotline: "Hotline",
    },
    vehicles: {
      title: "Vehicles",
      action: "Action",
      code: "Code",
      name: "Name",
      identifier: "Identifier",
      status: "Status",
      normal: "Normal",
      suspend: "Suspend",
      icon: "Icon",
      upload: "Upload",
      remove: "Remove",
    },
    staff: {
      title: "Staff",
      action: "Action",
      username: "Username",
      name: "Name",
      status: "Status",
      normal: "Active",
      suspend: "Inactive",
      role: "Role",
      user: "Carpark Staff",
      staff: "Staff",
      manager: "Manager",
      admin: "Admin",
      application: "Application",
      password: "Password",
      confirmPassword: "Confirmed Password",
      pwdNotMatch: "Those passwords didn't match",
    },
    news: {
      title: "News",
      action: "Action",
      content: "Content",
      image: "Image",
      url: "URL",
      status: "Status",
      publish: "Publish",
      draft: "Hide",
      hide: "Hide",
      subject: "Subject",
      upload: "Upload",
      remove: "Remove",
      createTime: "Create Time",
      postPeriod: "Post Period",
      from: "From",
      to: "To",
    },
    campaigns: {
      title: "Campaigns",
      action: "Action",
      period: "Submission Date",
      carparks: "Carparks",
      carpark: "Carpark",
      status: "Status",
      normal: "Active",
      suspend: "In-Active",
      from: "From",
      to: "To",
      name: "Name",
      parking: "Parking",
      purchasePeriod: "Purchase Period",
      vehicleLicenseMinimumValidity: "Vehicle Licence Minimum Validity (Days)",
      campaignId: "Campaign ID",
      carparkId: "Carpark ID",
      ballotingDate: "Ballot Date",
      ballotingResultDate: "Ballot Result Date",
      guidanceNote: "Guidance Note",
      upload: "Upload",
      remove: "Remove",
    },
    applications: {
      title: "Applications",
      rejectedTitle: "Applications Rejected",
      action: "Action",
      applicationNumber: "Application Number",
      applicantName: "Applicant Name",
      phone: "Phone",
      email: "Email",
      vehicleRegistrationMark: "Vehicle Registration Mark",
      vehicleLicenseExpireDate: "Vehicle License Expire Date",
      vehicleLicenseCopy: "Vehicle License Copy",
      physicalDisability: "Physical Disability",
      yes: "Yes",
      no: "No",
      disablePersonParkingPermit: "Disable Person Parking Permit",
      type: "Application Type",
      online: "Online",
      offline: "Offline",
      vehicleType: "Vehicle Type",
      remark: "Remark",
      status: "Status",
      pending: "Pending",
      rejected: "Rejected",
      approved: "Approved",
      staffRemark: "Staff Remark",
      staffRemarkDetail: "Staff Remark Detail",
      rejectedBy: "Rejected By",
      rejectedAt: "Rejected At",
      approvedBy: "Approved By",
      approvedAt: "Approved At",
      campaign: "Campaign",
      carpark: "Carpark",
      search: "Search",
      notSelected: "Not Selected",
      all: "All",
      sortBy: "Sort By:",
      createdDate: "Created Date",
      order: "Order:",
      rejectReason: "Reject Reason",
      applicationHardCopy: "Form Copy",
      pendingReason: "Pending Reason",
      vehicleLicenseOcrFailed: "Vehicle license could not be recognized",
      vehicleLicenseOcrNotMatch:
        "Vehicle license data did not match the OCR result",
      disablePersonParkingPermitOcrFailed:
        "Disable person parking permit could not be recognized",
      disablePersonParkingPermitOcrNotMatch:
        "Disable person parking permit data did not match the OCR result",
      remarkIsNotEmpty: "Remark is not empty",
      incorrectVehicleRegistrationMark: "Incorrect Vehicle Registration Mark",
      incorrectLicenceExpires: "Incorrect Licence Expires",
      other: "Other (Please Specify)",
      rejectReasonDetail: "Reject Reason Detail",
      resend: "Resend confirmation email",
      download_copy: "Download Copy",
      dateRange: "Created Date Range",
      from: "From",
      to: "To",
      reset: "Reset",
      disabledPermitProblem: "Disabled Permit problem",
      incorrectVehicleLicenseInformation:
        "Incorrect Vehicle License Information",
      noLicenceSubmit: "No Licence Submit",
      vehicleTypeTricycle: "Vehicle Type (Tricycle)",
      incompleteOrBlurryPhoto: "The photo is Incomplete or Blurry",
      vehicleTypeNotMatch: "Vehicle Type not match",
      cantOpenThePhoto: "Cannot open the photo",
      invalidVehicleRegistrationDocument:
        "Invalid Vehicle Registration Document",
      vehicleLicenseExpireDateRange: "Vehicle License Expire Date",
      approveRemark: {
        notExpiredOnTheDate: "Not expired on the date of application",
        theLicenceDateIsNotMatch:
          "The Licence Date is not match. But the Licence is valid",
        theLicenceDateIsNotMatchNNotExpired:
          "The Licence Date is not match. But the Licence is valid. And Not expired on the date of application",
        applicationFormInCarpark:
          "Application form in Carpark (Checked). Staff forgot upload",
        VehicleLicenceInCarpark:
          "Vehicle Licence in Carpark (Checked). Staff forgot upload",
      },
      rejectRemark: {
        incorrectInputByApplicant: "Incorrect input by Applicant",
        incorrectInputByStaff: "Incorrect input by Staff",
        applicantHasBeenContacted:
          "The applicant has been contacted and will reapply",
        LeftMessage: "Left a Message",
        cantReachTheClient:
          "Contacted many times but failed to connect, unable to leave a message",
      },
    },
    applicationGenerator: {
      title: "Application Generator",
      carpark: "Car Park:",
      vehicleType: "Vehicle Type:",
      generate: "Generate",
      campaign: "Campaign:",
      noRunningCampaign: "No Running Campaign For This Carpark",
      noCarpark: "No Carpark",
      noVehicleType: "No Vehicle Type",
      notSelected: "Not Selected",
    },
    applicationExport: {
      title: "Application Export",
      send: "Mail Out Application List",
      campaign: "Campaign:",
      success: "Sent successfully.",
      error: "Delivery failed. Please try again.",
      download: "Download Application List",
    },
    reports: {
      title: "Report",
      dailyCount: "Daily Report",
      manual: "Manual Handling",
      exception: "Exceptional Case",
      date: "Date",
      online: "Online Application",
      offline: "Offline Application",
      dateRange: "Date Range",
    },
    exportReports: {
      title: "Export Report",
      dateRange: "Date Range",
      campaign: "Campaign",
      notSelected: "Not Selected",
      carpark: "Car Park",
      from: "From",
      to: "To",
      export: "Export",
      reset: "Reset",
      reportType: "Report Type",
      motorVehicleLicense: "Motor Vehicle License",
      vehicleLicenseFile: "Vehicle License File",
      checkListFile: "Check List File",
      applicationReject: "Reject report",
      applicationRemark: "Remark report",
      date: "Date",
      applicationType: {
        label: "Application Type",
        online: "Online",
        offline: "Offline",
      },
      maxDays: "(Max {{days}} days)",
      downloadFailed: "Download report failed",
      downloadStarted: "Report is being downloaded",
      form: {
        campaignIsRequired: "Campaign is required",
        carparkIsRequired: "Car park is required",
        reportTypeIsRequired: "Report type is required",
        createdAtFromIsRequired: "Date range (from) is required",
        createdAtToIsRequired: "Date range (to) is required",
        createdAtDateIsRequired: "Date is required",
        createdAtOutOfRange: "Date range is out of range",
      },
    },
    activityLog: {
      title: "Activity Logs",
      event: "Event",
      createdBy: "Created By",
      createdDate: "Created Date",
      announcementCreate: "Create Announcement",
      announcementUpdate: "Update Announcement",
      announcementDelete: "Delete Announcement",
      applicationCreate: "Create Application",
      applicationConfirm: "Confirm Application",
      applicationReject: "Reject Application",
      applicationGenerate: "Generate Application",
      applicationExport: "Export Application",
      campaignCreate: "Create Campaign",
      campaignUpdate: "Update Campaign",
      carparkCreate: "Create Carpark",
      carparkUpdate: "Update Carpark",
      carparkDelete: "Delete Carpark",
      userCreate: "Create User",
      userUpdate: "Update User",
      userDelete: "Delete User",
      userLogin: "User Login",
      vehicleTypeCreate: "Create Vehicle Type",
      vehicleTypeUpdate: "Update Vehicle Type",
      vehicleTypeDelete: "Delete Vehicle Type",
      settingUpdate: "Setting Update",
      ipCreate: "Create IP Restriction",
      ipUpdate: "Update IP Restriction",
      ipDelete: "Delete IP Restriction",
    },
    setting: {
      title: "Setting",
      notificationEmail: "Notification Email",
      exportEmail: "Export Email",
      passwordEmail: "Password Email",
      tdWebsite: "Transport Department’s website (English)",
      tdWebsiteZH: "Transport Department’s website (Chinese) ",
      guidanceNote: "Guidance Note",
      upload: "Upload",
      personalInformationCollectionStatement:
        "Personal Information Collection Statement",
      remove: "Remove",
    },
  },
  login: {
    login: "Login",
    remarks: "Enter your username and password to login",
    username: "Username",
    password: "Password",
    success: "Login Success",
    error: "Login Failed",
    unauthorized: "Forbidden: IP address rejected.",
  },
  security: {
    title: "Security",
    ipRestriction: {
      title: "IP Restriction",
      action: "Action",
      name: "Name",
      address: "Address",
    },
  },
};
