import ActionTypes from "./types";
import applicationAPIHelper from "../../helpers/api/applicationAPIHelper";

/**
 * Get list for Applications
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getList(
  searchFilters = null,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.getList(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.APPLICATION_GET_LIST,
          data: {
            list: data,
            totalPages: response.responseDetail.totalPages,
          },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Select the application for form input
 * @param {Object} data The data of selected application
 * @returns {{data: *, type: string}} Redux Action
 */
export function select(data) {
  return {
    type: ActionTypes.APPLICATION_SELECT,
    data,
  };
}

/**
 * Update existing application
 * @param {String} id ID of the application to be updated
 * @param {Object} data Data for updating a application
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function update(id, data, token, successCallback, failCallback) {
  return (dispatch) => {
    applicationAPIHelper.update(
      id,
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.APPLICATION_SELECT,
          data: null, // Reset selected application
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function updateSearchFilters(data) {
  return {
    type: ActionTypes.APPLICATION_UPDATE_SEARCH,
    data,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.APPLICATION_RESET_SEARCH,
  };
}

export function generateApplication(
  data,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.generate(
      data,
      token,
      (response) => {
        const { responseCode, responseDetail } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.APPLICATION_GENERATE,
        });
        if (typeof successCallback === "function")
          successCallback(responseDetail.exportHistory.attachment.fileUrl);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function resendEmail(
  id,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.resendEmail(
      id,
      token,
      (response) => {
        const { responseCode, responseDetail } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.APPLICATION_RESEND_EMAIL,
        });
        if (typeof successCallback === "function")
          successCallback(responseDetail);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function exportApplication(
  id,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    applicationAPIHelper.exportApp(
      id,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.APPLICATION_EXPORT,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const ApplicationActions = {
  getList,
  select,
  update,
  updateSearchFilters,
  resetSearchFilters,
  generateApplication,
  resendEmail,
  exportApplication,
};
export default ApplicationActions;
