import React, { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MENUITEMS } from "../../../constants/menu";
import i18n from "../../../i18n/i18n";
import { getPermission } from "../../../redux/actions/account";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainMenu: [],
    };
    const { dispatch } = this.props;
    this.getPermission = bindActionCreators(getPermission, dispatch);
  }

  componentDidMount() {
    this.getFilteredMainMenu();
  }

  getFilteredMainMenu() {
    const { account } = this.props;
    let filteredMainMenu = [];
    this.getPermission(
      account.token,
      (permission, role) => {
        const currentUrl = window.location.pathname;

        const { resources } = permission;
        filteredMainMenu = MENUITEMS.reduce((acc, item) => {
          // special handling of staff role, only /applications in menu is shown
          if (role === "staff") {
            if (item.path === "/applications" || item.name === "home") {
              acc.push(item);
            }
            return acc;
          }

          if (
            resources.includes(item.name) ||
            resources.includes("*") ||
            item.name === "home"
          ) {
            acc.push(item);
          }
          return acc;
        }, []);

        this.setState({ mainMenu: filteredMainMenu }, () => {
          filteredMainMenu.forEach((item) => {
            if (!item.children && item.path === currentUrl) {
              if (item.path === currentUrl) this.setNavActive(item);
            } else if (item.children) {
              item.children.forEach((subItem) => {
                if (subItem.path === currentUrl) this.setNavActive(subItem);
                if (subItem.children) {
                  subItem.children.forEach((subSubItem) => {
                    if (subSubItem.path === currentUrl)
                      this.setNavActive(subSubItem);
                  });
                }
              });
            }
          });
        });
      },
      (error) => {
        console.log(error);
        filteredMainMenu = MENUITEMS.filter((item) => item.name === "home");
        this.setState({ mainMenu: filteredMainMenu });
      }
    );
  }

  setNavActive(item) {
    const { mainMenu } = this.state;
    const tmpMainMenu = mainMenu;
    tmpMainMenu.forEach((menuItem, i) => {
      if (menuItem !== item) tmpMainMenu[i].active = false;
      if (menuItem.children && menuItem.children.includes(item))
        tmpMainMenu[i].active = true;
      if (menuItem.children) {
        menuItem.children.forEach((submenuItems, j) => {
          if (submenuItems.children && submenuItems.children.includes(item))
            tmpMainMenu[i].active = true;
          if (submenuItems !== item) tmpMainMenu[i].children[j].active = false;
        });
      }
      if (menuItem === item) tmpMainMenu[i].active = !menuItem.active;
    });
    this.setState({ mainMenu: tmpMainMenu });
  }

  render() {
    const { mainMenu } = this.state;

    return (
      <div className="page-sidebar custom-scrollbar page-sidebar-open">
        <ul className="sidebar-menu">
          {mainMenu.map((menuItem, i) => (
            <li className={`${menuItem.active ? "active" : ""}`} key={i}>
              {menuItem.sidebartitle && (
                <div className="sidebar-title">
                  {i18n.t(menuItem.sidebarkey)}
                </div>
              )}

              {menuItem.type === "sub" && (
                <a
                  className="sidebar-header "
                  href="javascript:void(0)"
                  onClick={() => this.setNavActive(menuItem)}
                >
                  <i className={`icon-${menuItem.icon}`} />
                  <span>{menuItem.title}</span>
                  <i className="fa fa-angle-right pull-right" />
                </a>
              )}

              {menuItem.type === "link" && (
                <Link
                  to={`${process.env.PUBLIC_URL}/${i18next.language}${menuItem.path}`}
                  className={`sidebar-header ${
                    menuItem.active ? "Active" : ""
                  }`}
                  onClick={() => this.setNavActive(menuItem)}
                >
                  <i className={`icon-${menuItem.icon}`} />
                  <span>{i18n.t(menuItem.key)}</span>
                  {menuItem.children ? (
                    <i className="fa fa-angle-right pull-right" />
                  ) : (
                    ""
                  )}
                </Link>
              )}

              {menuItem.children && (
                <ul
                  className={`sidebar-submenu ${
                    menuItem.active ? "menu-open" : ""
                  }`}
                  style={
                    menuItem.active
                      ? { opacity: 1, transition: "opacity 500ms ease-in" }
                      : {}
                  }
                >
                  {menuItem.children.map((childrenItem, index) => (
                    <li
                      key={index}
                      className={
                        childrenItem.children && childrenItem.active
                          ? "active"
                          : ""
                      }
                    >
                      {childrenItem.type === "sub" && (
                        <a
                          href="javascript:void(0)"
                          onClick={() => this.setNavActive(childrenItem)}
                        >
                          <i className="fa fa-angle-right" />
                          {childrenItem.title}
                        </a>
                      )}

                      {childrenItem.type === "link" && (
                        <Link
                          to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                          className={childrenItem.active ? "active" : ""}
                          onClick={() => this.setNavActive(childrenItem)}
                        >
                          <i className="fa fa-angle-right" />
                          {childrenItem.title}
                        </Link>
                      )}

                      {childrenItem.children && (
                        <ul
                          className={`sidebar-submenu ${
                            childrenItem.active ? "menu-open" : ""
                          }`}
                        >
                          {childrenItem.children.map((childrenSubItem, key) => (
                            <li
                              className={childrenSubItem.active ? "active" : ""}
                              key={key}
                            >
                              {childrenSubItem.type === "link" && (
                                <Link
                                  to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                  className={
                                    childrenSubItem.active ? "active" : ""
                                  }
                                >
                                  <i className="fa fa-angle-right" />
                                  {childrenSubItem.title}
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps)(Sidebar);
