import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Pagination } from "react-bootstrap";
import actions, { useActions } from "../../../redux/actions";

const PaginationItems = (props) => {
  const { handleChange } = props;
  const { searchFilters, totalPages } = useSelector(
    (state) => state.activityLog
  );

  const returnItems = [];
  for (let number = 1; number <= totalPages; number += 1) {
    returnItems.push(
      <Pagination.Item
        key={number}
        active={number === searchFilters.page}
        onClick={() =>
          searchFilters.page === number ? null : handleChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return returnItems;
};

const PaginationBar = (props) => {
  const { fetchData } = props;
  const { searchFilters, totalPages } = useSelector(
    (state) => state.activityLog
  );
  const activityLogActions = useActions(actions.ActivityLogActions);

  const handleChange = (pageNum) => {
    activityLogActions.updateSearchFilters({
      ...searchFilters,
      page: pageNum,
    });
  };

  useEffect(() => {
    fetchData();
  }, [searchFilters.page]);

  return (
    <div className="pagination-container">
      <Pagination>
        <Pagination.First
          onClick={() => handleChange(1)}
          disabled={searchFilters.page === 1}
        />
        <Pagination.Prev
          onClick={() => handleChange(searchFilters.page - 1)}
          disabled={searchFilters.page === 1}
        />
        <PaginationItems handleChange={handleChange} />
        <Pagination.Next
          onClick={() => handleChange(searchFilters.page + 1)}
          disabled={searchFilters.page === totalPages}
        />
        <Pagination.Last
          onClick={() => handleChange(totalPages)}
          disabled={searchFilters.page === totalPages}
        />
      </Pagination>
    </div>
  );
};

export default PaginationBar;
