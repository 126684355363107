import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultVehicleType = {
  _id: "",
  name: "",
  name_zh: "",
  icon: null,
  code: "",
  identifier: "",
  status: "normal",
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultVehicleType,
  },
});

const vehicleTypeReducer = {
  vehicleType: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.VEHICLE_TYPE_GET_LIST:
        state = {
          ...state,
          list: action.data,
        };
        return state;
      case ActionTypes.VEHICLE_TYPE_SELECT:
        state = {
          ...state,
          selected: action.data
            ? { ...defaultVehicleType, ...action.data }
            : { ...defaultVehicleType },
        };
        return state;
      default:
        return state;
    }
  },
};

export default vehicleTypeReducer;
