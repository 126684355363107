export const tableHeader = [
  {
    value: "event",
    key: "profile.activityLog.event",
  },
  {
    value: "createdBy",
    key: "profile.activityLog.createdBy",
  },
  {
    value: "createdAt",
    key: "profile.activityLog.createdDate",
  },
];

export const activityLogEvents = [
  {
    value: "announcement-create",
    key: "profile.activityLog.announcementCreate",
    module: "announcement",
  },
  {
    value: "announcement-update",
    key: "profile.activityLog.announcementUpdate",
    module: "announcement",
  },
  {
    value: "announcement-delete",
    key: "profile.activityLog.announcementDelete",
    module: "announcement",
  },
  {
    value: "application-create",
    key: "profile.activityLog.applicationCreate",
    module: "application",
  },
  {
    value: "application-confirm",
    key: "profile.activityLog.applicationConfirm",
    module: "application",
  },
  {
    value: "application-reject",
    key: "profile.activityLog.applicationReject",
    module: "application",
  },
  {
    value: "application-generate",
    key: "profile.activityLog.applicationGenerate",
    module: "application",
  },
  {
    value: "application-export",
    key: "profile.activityLog.applicationExport",
    module: "application",
  },
  {
    value: "campaign-create",
    key: "profile.activityLog.campaignCreate",
    module: "campaign",
  },
  {
    value: "campaign-update",
    key: "profile.activityLog.campaignUpdate",
    module: "campaign",
  },
  {
    value: "carpark-create",
    key: "profile.activityLog.carparkCreate",
    module: "carpark",
  },
  {
    value: "carpark-update",
    key: "profile.activityLog.carparkUpdate",
    module: "carpark",
  },
  {
    value: "carpark-delete",
    key: "profile.activityLog.carparkDelete",
    module: "carpark",
  },
  {
    value: "user-create",
    key: "profile.activityLog.userCreate",
    module: "user",
  },
  {
    value: "user-update",
    key: "profile.activityLog.userUpdate",
    module: "user",
  },
  {
    value: "user-delete",
    key: "profile.activityLog.userDelete",
    module: "user",
  },
  {
    value: "user-login",
    key: "profile.activityLog.userLogin",
    module: "user",
  },
  {
    value: "vehicletype-create",
    key: "profile.activityLog.vehicleTypeCreate",
    module: "vehicletype",
  },
  {
    value: "vehicletype-update",
    key: "profile.activityLog.vehicleTypeUpdate",
    module: "vehicletype",
  },
  {
    value: "vehicletype-delete",
    key: "profile.activityLog.vehicleTypeDelete",
    module: "vehicletype",
  },
  {
    value: "setting-update",
    key: "profile.activityLog.settingUpdate",
    module: "setting",
  },
  {
    value: "ip-create",
    key: "profile.activityLog.ipCreate",
    module: "ip",
  },
  {
    value: "ip-update",
    key: "profile.activityLog.ipUpdate",
    module: "ip",
  },
  {
    value: "ip-delete",
    key: "profile.activityLog.ipDelete",
    module: "ip",
  },
];
