export const tableHeader = {
  dailyCount: [
    {
      value: "date",
      key: "profile.reports.date",
    },
    {
      value: "online",
      key: "profile.reports.online",
    },
    {
      value: "offline",
      key: "profile.reports.offline",
    },
  ],
  manualHandling: [
    {
      value: "no",
      key: "profile.applications.applicationNumber",
      sortKey: "no",
    },
    {
      value: "applicantName",
      key: "profile.applications.applicantName",
    },
    {
      value: "phone",
      key: "profile.applications.phone",
    },
    {
      value: "email",
      key: "profile.applications.email",
    },
    {
      value: "vehicleRegistrationMark",
      key: "profile.applications.vehicleRegistrationMark",
    },
    {
      value: "status",
      key: "profile.applications.status",
      sortKey: "status",
    },
    {
      value: "pendingReason",
      key: "profile.applications.pendingReason",
    },
    {
      value: "createdAt",
      key: "profile.applications.createdDate",
      sortKey: "_id",
    },
  ],
  exceptionalCase: [
    {
      value: "no",
      key: "profile.applications.applicationNumber",
      sortKey: "no",
    },
    {
      value: "applicantName",
      key: "profile.applications.applicantName",
    },
    {
      value: "phone",
      key: "profile.applications.phone",
    },
    {
      value: "email",
      key: "profile.applications.email",
    },
    {
      value: "vehicleRegistrationMark",
      key: "profile.applications.vehicleRegistrationMark",
    },
    {
      value: "status",
      key: "profile.applications.status",
      sortKey: "status",
    },
    {
      value: "rejectReason",
      key: "profile.applications.rejectReason",
    },
    {
      value: "rejectReasonDetail",
      key: "profile.applications.rejectReasonDetail",
    },
    {
      value: "staffRemark",
      key: "profile.applications.staffRemark",
    },
    {
      value: "staffRemark",
      key: "profile.applications.staffRemarkDetail",
    },
    {
      value: "createdAt",
      key: "profile.applications.createdDate",
      sortKey: "_id",
    },
  ],
};

export const tabs = [
  {
    value: 0,
    key: "profile.reports.dailyCount",
  },
  {
    value: 1,
    key: "profile.reports.manual",
  },
  {
    value: 2,
    key: "profile.reports.exception",
  },
];

export const status = [
  {
    value: "pending",
    key: "profile.applications.pending",
  },
  {
    value: "rejected",
    key: "profile.applications.rejected",
  },
  {
    value: "approved",
    key: "profile.applications.approved",
  },
];

export const type = [
  {
    value: "online",
    key: "profile.applications.online",
  },
  {
    value: "offline",
    key: "profile.applications.offline",
  },
];

export const sortingMethods = [
  {
    value: "_id",
    key: "profile.applications.createdDate",
  },
  {
    value: "no",
    key: "profile.applications.applicationNumber",
  },
  {
    value: "status",
    key: "profile.applications.status",
  },
];

export const rejectReasons = [
  {
    value: "Incorrect Vehicle Registration Mark",
    key: "profile.applications.incorrectVehicleRegistrationMark",
  },
  {
    value: "Incorrect Licence Expires",
    key: "profile.applications.incorrectLicenceExpires",
  },
  {
    value: "Other",
    key: "profile.applications.other",
  },
];

export const pendingReasons = [
  {
    value: "vehicle_license_ocr_failed",
    key: "profile.applications.vehicleLicenseOcrFailed",
  },
  {
    value: "vehicle_license_ocr_not_match",
    key: "profile.applications.vehicleLicenseOcrNotMatch",
  },
  {
    value: "disable_person_parking_permit_ocr_failed",
    key: "profile.applications.disablePersonParkingPermitOcrFailed",
  },
  {
    value: "disable_person_parking_permit_ocr_not_match",
    key: "profile.applications.disablePersonParkingPermitOcrNotMatch",
  },
  {
    value: "remark_is_not_empty",
    key: "profile.applications.remarkIsNotEmpty",
  },
];
