export default function formattedDate(date, showTime = true) {
  const year = `${date.getFullYear()}`;
  const month =
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : `${date.getMonth() + 1}`;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hour =
    date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const minute =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

  if (showTime) return `${year}-${month}-${day} ${hour}:${minute}`;
  return `${year}-${month}-${day}`;
}

export function removeEmpty(object) {
  // remove empty value or empty object from object
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] && typeof object[key] === "object") {
      const subObject = removeEmpty(object[key]);
      if (Object.keys(subObject).length > 0) {
        newObject[key] = subObject;
      }
    } else if (object[key] !== null && object[key] !== "") {
      newObject[key] = object[key];
    }
  });
  return newObject;
}
