import React, { useEffect, useMemo, useState } from "react";
import ArrGet from "get-value";
import { useTranslation } from "react-i18next";
import zh from "date-fns/locale/zh-TW";
import en from "date-fns/locale/en-US";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Collapse } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, { registerLocale } from "react-datepicker";
import SweetAlert from "react-bootstrap-sweetalert";
import { object, string } from "yup";
import actions, { useActions } from "../../../redux/actions";
import formattedDate, { removeEmpty } from "../../../helpers/generalFunctions";
import {
  reportFieldsEnum,
  reportStatus,
  reportTypeEnum,
  reportTypes,
} from "../../../constants/exportreport";
import Poller from "../../../helpers/poller";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";
import { exportReport } from "../../../redux/actions/exportreport";

registerLocale("zh", zh);
registerLocale("en", en);

const SearchBar = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn btn-primary search-box-bar"
      onClick={() => setOpen(!open)}
      aria-controls="example-collapse-text"
      aria-expanded={open}
    >
      <i className="icofont icofont-search search-box-icon" />
      <span className="search-box-header-text">
        {t("profile.applications.search")}
      </span>
    </button>
  );
};

const SearchBox = (props) => {
  const { t, i18n } = useTranslation();
  const { searchFilters } = useSelector((state) => state.exportreport);
  const exportReportAction = useActions(actions.ExportReportAction);
  const [open, setOpen] = useState(true);
  const [query, setQuery] = useState(searchFilters);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const accountActions = useActions(actions.AccountActions);
  const campaignActions = useActions(actions.CampaignActions);
  const { token } = useSelector((state) => state.account);
  const carparkActions = useActions(actions.CarparkActions);
  const campaigns = useSelector((state) => state.campaign);
  const carparks = useSelector((state) => state.carpark);

  const isOffline = (q, type) => {
    if (!q) return false;
    return (
      q.applicationType === "offline" && type === reportTypeEnum.CHECK_LIST_FILE
    );
  };

  const isRequiredFieldForOfflineCheckList = (currentType, field) => {
    const reportType =
      reportTypes.find((type) => type.value === currentType) || {};
    return reportType.requiredFieldsOffline.includes(field);
  };

  const isRequiredField = (currentType, field) => {
    const reportType =
      reportTypes.find((type) => type.value === currentType) || {};
    return reportType.requiredFields.includes(field);
  };

  const isInDateRange = (currentType, from, to) => {
    const reportType =
      reportTypes.find((type) => type.value === currentType) || {};
    if (!reportType.maxDays) return true;
    // from and to are String type in format of YYYY-MM-DD
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const diffTime = Math.abs(toDate - fromDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= reportType.maxDays;
  };

  const formSchema = object({
    campaign: string().test(
      "campaignRequireTest",
      t("profile.exportReports.form.campaignIsRequired"),
      function (value) {
        if (isRequiredField(this.parent.type, reportFieldsEnum.CAMPAIGN)) {
          return !!value;
        }
        return true;
      }
    ),
    carpark: string().test(
      "carparkRequireTest",
      t("profile.exportReports.form.carparkIsRequired"),
      function (value) {
        if (isRequiredField(this.parent.type, reportFieldsEnum.CARPARK)) {
          return !!value;
        }
        return true;
      }
    ),
    type: string().required(
      t("profile.exportReports.form.reportTypeIsRequired")
    ),
    createdAt: object({
      from: string().test(
        "createdAtFromRequireTest",
        t("profile.exportReports.form.createdAtFromIsRequired"),
        function (value) {
          const { type } = this.options.context;

          if (
            isOffline(query, type) &&
            isRequiredFieldForOfflineCheckList(type, reportFieldsEnum.FROM)
          ) {
            return !!value;
          }

          if (isRequiredField(type, reportFieldsEnum.FROM)) {
            return !!value;
          }
          return true;
        }
      ),
      to: string()
        .test(
          "createdAtToRequireTest",
          t("profile.exportReports.form.createdAtToIsRequired"),
          function (value) {
            const { type } = this.options.context;

            if (
              isOffline(query, type) &&
              isRequiredFieldForOfflineCheckList(type, reportFieldsEnum.TO)
            ) {
              return !!value;
            }

            if (isRequiredField(type, reportFieldsEnum.TO)) {
              return !!value;
            }
            return true;
          }
        )
        .test(
          "createdAtToRangeTest",
          t("profile.exportReports.form.createdAtOutOfRange"),
          function (value) {
            const { type, createdAt } = this.options.context;
            if (!value) return true;

            if (!isInDateRange(type, createdAt.from, value)) {
              return false;
            }
            return true;
          }
        ),
      date: string().test(
        "createdAtDateRequireTest",
        t("profile.exportReports.form.createdAtDateIsRequired"),
        function () {
          const { type, createdAt } = this.options.context;

          if (
            query.applicationType === "offline" &&
            type === reportTypeEnum.CHECK_LIST_FILE
          )
            return true;

          if (isRequiredField(type, reportFieldsEnum.DATE)) {
            return !!createdAt.from;
          }
          return true;
        }
      ),
    }),
  });

  useEffect(() => {
    campaignActions.getList(
      { carpark: query.carpark },
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
  }, [query.carpark]);

  useEffect(() => {
    carparkActions.getList(
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
  }, []);

  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };

  const setReportType = (e) => {
    exportReportAction.setReportType(e.target.value);
  };

  const setDate = (date, type) => {
    setQuery({
      ...query,
      createdAt: {
        ...query.createdAt,
        [type]: date ? formattedDate(date, false) : undefined,
      },
    });
  };

  const setSameDate = (date) => {
    setQuery({
      ...query,
      createdAt: {
        ...query.createdAt,
        from: date ? formattedDate(date, false) : undefined,
        to: date ? formattedDate(date, false) : undefined,
      },
    });
  };

  const downloadReport = async (url) => {
    try {
      const a = document.createElement("a");
      a.href = url;
      a.click();
      setAlert({
        show: true,
        title: t("success"),
        content: t("profile.exportReports.downloadStarted"),
        type: "success",
      });
    } catch (ex) {
      setAlert({
        show: true,
        title: t("error"),
        content: t("profile.exportReports.downloadFailed"),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const pollFailedCallback = () => {
    setAlert({
      show: true,
      title: t("error"),
      content: t("profile.exportReports.downloadFailed"),
      type: "error",
    });
    setIsLoading(false);
  };

  const pollSuccessCallback = (response, retryCallback) => {
    if (response?.responseDetail?.status !== reportStatus.SUCCESSFUL) {
      retryCallback();
      return;
    }
    downloadReport(response?.responseDetail?.fileUrl);
  };
  const pollReport = async (reportId) => {
    setIsLoading(true);
    const poller = new Poller(
      (retryCallback) =>
        exportReportAction.getReportProgress(
          reportId,
          token,
          (response) =>
            pollSuccessCallback(response, retryCallback.bind(poller)),
          pollFailedCallback
        ),
      (e) => {
        setIsLoading(false);
      }
    );
    await poller.poll();
  };

  const handleSearch = () => {
    try {
      formSchema.validateSync(query, { abortEarly: false, context: query });
      const cleanQuery = removeEmpty(query);
      exportReportAction.setSearchFilters(cleanQuery);

      if (cleanQuery.carpark) delete cleanQuery.carpark;
      if (Object.values(reportTypeEnum).includes(query.type)) {
        exportReportAction.exportReport(cleanQuery, token, (response) =>
          pollReport(response?.responseDetail?.id)
        );
      }
    } catch (error) {
      setAlert({
        show: true,
        title: t("error"),
        content: error?.errors?.map((e) => e).join(", "),
        type: "error",
      });
    }
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
    // window.location.reload();
  };

  const getReportTypeConstant = function () {
    if (!query) return {};
    const match = { ...reportTypes.find((type) => query.type === type.value) };
    if (query.applicationType && query.applicationType === "offline") {
      match.fields = ArrGet(match, "fieldsOffline");
      match.requiredFields = ArrGet(match, "requiredFieldsOffline");
    }
    return match;
  };

  useEffect(() => {
    setQuery(searchFilters);
  }, [searchFilters]);

  const getShowField = useMemo(
    () => (targetField) => {
      // get fields for report type

      const fields = getReportTypeConstant(query)?.fields || [];
      const shouldShow = fields.some((field) => {
        if (typeof field === "string" && field === targetField) {
          return true;
        }
        if (typeof field === "object" && field.field === targetField) {
          return field.preRequiredFields.every((preRequiredField) => {
            return query[preRequiredField];
          });
        }
        return false;
      });
      return shouldShow;
    },
    [query, reportTypes]
  );

  const getReportType = useMemo(
    () => () => {
      return getReportTypeConstant(query);
    },
    [query.type, reportTypes]
  );

  return (
    <div className="search-box-container">
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={handleCloseAlert}
      >
        {alert.content}
      </SweetAlert>
      <SpinnerOverlay loading={isLoading} style={{ zIndex: 10 }} />
      <SearchBar open={open} setOpen={setOpen} />
      <Collapse in={open}>
        <div className="card search-box">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.exportReports.reportType")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="type"
                  as="select"
                  value={query.type}
                  onChange={setReportType}
                >
                  {
                    <option key="" value="">
                      {t("profile.exportReports.notSelected")}
                    </option>
                  }
                  {reportTypes.map((type) => {
                    return (
                      <option key={type.value} value={type.value}>
                        {t(type.key)}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            {getShowField(reportFieldsEnum.CARPARK) && (
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  {t("profile.exportReports.carpark")}
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="carpark"
                    as="select"
                    value={query.carpark}
                    onChange={handleChange}
                  >
                    {
                      <option key="" value="">
                        {t("profile.exportReports.notSelected")}
                      </option>
                    }
                    {carparks.list.map((carpark) => {
                      return (
                        <option key={carpark._id} value={carpark._id}>
                          {i18n.language === "zh"
                            ? carpark.name_zh
                            : carpark.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            )}

            {getShowField(reportFieldsEnum.CAMPAIGN) && (
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  {t("profile.exportReports.campaign")}
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    name="campaign"
                    as="select"
                    value={query.campaign}
                    onChange={handleChange}
                  >
                    {
                      <option key="" value="">
                        {t("profile.exportReports.notSelected")}
                      </option>
                    }
                    {campaigns.list.map((campaign) => {
                      return (
                        <option key={campaign._id} value={campaign._id}>
                          {campaign.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
            )}

            {(getShowField(reportFieldsEnum.FROM) ||
              getShowField(reportFieldsEnum.TO)) && (
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  {t("profile.exportReports.dateRange")}
                  {getReportType()?.maxDays &&
                    ` ${t("profile.exportReports.maxDays", {
                      days: getReportType()?.maxDays,
                    })}`}
                </Form.Label>
                {getShowField(reportFieldsEnum.FROM) && (
                  <Col sm="4">
                    <span style={{ marginRight: 20 }}>
                      {t("profile.exportReports.from")}
                    </span>
                    <DatePicker
                      selected={Date.parse(query?.createdAt?.from)}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => setDate(date, "from")}
                      locale={i18n.language}
                      popperPlacement="right"
                    />
                  </Col>
                )}
                {getShowField(reportFieldsEnum.TO) && (
                  <Col sm="4">
                    <span style={{ marginRight: 20 }}>
                      {t("profile.exportReports.to")}
                    </span>
                    <DatePicker
                      selected={Date.parse(query?.createdAt?.to)}
                      dateFormat="yyyy-MM-dd"
                      onChange={(date) => setDate(date, "to")}
                      locale={i18n.language}
                      popperPlacement="right"
                    />
                  </Col>
                )}
              </Form.Group>
            )}

            {getShowField(reportFieldsEnum.DATE) && (
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  {t("profile.exportReports.date")}
                </Form.Label>
                <Col sm="8">
                  <DatePicker
                    selected={Date.parse(query.createdAt.from)}
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => {
                      setSameDate(date);
                    }}
                    locale={i18n.language}
                    popperPlacement="right"
                  />
                </Col>
              </Form.Group>
            )}

            {getShowField(reportFieldsEnum.APPLICATION_TYPE) && (
              <Form.Group
                as={Row}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Label column sm="3">
                  {t("profile.exportReports.applicationType.label")}
                </Form.Label>
                <Col sm="8">
                  {[
                    {
                      label: "profile.exportReports.applicationType.online",
                      value: "online",
                    },
                    {
                      label: "profile.exportReports.applicationType.offline",
                      value: "offline",
                    },
                  ].map((s) => {
                    return (
                      <Form.Check
                        inline
                        name="applicationType"
                        type="radio"
                        label={t(s.label)}
                        value={s.value}
                        onChange={handleChange}
                        className="radio-primary"
                        checked={query.applicationType === s.value}
                      />
                    );
                  })}
                </Col>
              </Form.Group>
            )}
          </Form>

          <div>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                handleSearch();
              }}
            >
              {t("profile.exportReports.export")}
            </button>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                // setOpen(false);
                exportReportAction.resetSearchFilters();
              }}
            >
              {t("profile.exportReports.reset")}
            </button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SearchBox;
