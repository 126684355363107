import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import "./setting.css";
import Settings from "./settings";

const Setting = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Breadcrumb
        title={t("profile.setting.title")}
        parent={t("profile.title")}
      />
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="table-responsive">
              <Settings />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Setting);
