import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import actions, { useActions } from "../../../redux/actions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";

const Generator = (props) => {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const applicationActions = useActions(actions.ApplicationActions);
  const vehicleTypeActions = useActions(actions.VehicleTypeActions);
  const carparkActions = useActions(actions.CarparkActions);
  const campaignActions = useActions(actions.CampaignActions);
  const accountActions = useActions(actions.AccountActions);
  const carparkList = useSelector((state) => state.carpark.list);
  const vehicleTypeList = useSelector((state) => state.vehicleType.list);
  const campaignList = useSelector((state) => state.campaign.list);
  const [campaigns, setCampaigns] = useState([]);
  const [carpark, setCarpark] = useState(null);
  const [vehicleType, setVehicleType] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateApplication = () => {
    setIsLoading(true);
    const data = {
      carpark: carpark._id,
      vehicletype: vehicleType._id,
      campaign: campaign._id,
    };
    applicationActions.generateApplication(
      data,
      token,
      (url) => {
        window.open(url, "_blank");
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    vehicleTypeActions.getList(
      token,
      (response) => {
        const result = response.responseDetail.data;
        if (Array.isArray(result) && result.length >= 1) {
          setVehicleType(result[0]);
        } else {
          setVehicleType(null);
        }
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
    carparkActions.getList(
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
    campaignActions.getList(
      {},
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
  }, []);

  useEffect(() => {
    if (carpark) {
      const filteredCampaigns = campaignList.filter(
        (c) => c.carpark._id === carpark._id
      );
      setCampaign(filteredCampaigns[0]);
      setCampaigns(filteredCampaigns);
    }
  }, [carpark]);

  return (
    <div className="app-generator-container" style={{ minHeight: "60vh" }}>
      <div className="app-generator-row">
        <span className="app-generator-tag">
          {t("profile.applicationGenerator.carpark")}
        </span>

        {carparkList.length >= 1 ? (
          <Dropdown className="app-generator-dropdown">
            <Dropdown.Toggle className="btn btn-light">
              {carpark
                ? i18n.language === "zh"
                  ? carpark.name_zh
                  : carpark.name
                : t("profile.applicationGenerator.notSelected")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {carparkList.map((c) => (
                <Dropdown.Item onClick={() => setCarpark(c)}>
                  {i18n.language === "zh" ? c.name_zh : c.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          t("profile.applicationGenerator.noCarpark")
        )}
      </div>

      <div className="app-generator-row">
        <span className="app-generator-tag">
          {t("profile.applicationGenerator.campaign")}
        </span>

        {campaigns.length >= 1 ? (
          <Dropdown className="app-generator-dropdown">
            <Dropdown.Toggle className="btn btn-light">
              {campaign.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {campaigns.map((c) => (
                <Dropdown.Item onClick={() => setCampaign(c)}>
                  {c.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          t("profile.applicationGenerator.noRunningCampaign")
        )}
      </div>

      <div className="app-generator-row">
        <span className="app-generator-tag">
          {t("profile.applicationGenerator.vehicleType")}
        </span>

        {vehicleType ? (
          <Dropdown className="app-generator-dropdown">
            <Dropdown.Toggle className="btn btn-light">
              {i18n.language === "zh" ? vehicleType.name_zh : vehicleType.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {vehicleTypeList.map((v) => (
                <Dropdown.Item onClick={() => setVehicleType(v)}>
                  {i18n.language === "zh" ? v.name_zh : v.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          t("profile.applicationGenerator.noVehicleType")
        )}
      </div>

      <button
        type="button"
        className="btn btn-primary app-generator-btn"
        onClick={handleGenerateApplication}
        disabled={!carpark || !campaign || !vehicleType || isLoading}
      >
        {t("profile.applicationGenerator.generate")}
      </button>
      <SpinnerOverlay loading={isLoading} />
    </div>
  );
};

export default Generator;
