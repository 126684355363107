import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultApplication = {
  _id: null,
  no: null,
  applicantName: "",
  phone: "",
  email: "",
  vehicleRegistrationMark: "",
  vehicleLicenseExpireDate: "",
  vehicleLicenseCopy: null,
  isPhysicalDisability: "",
  disablePersonParkingPermit: null,
  status: "",
  createdBy: "",
  updatedBy: null,
  updatedAt: "",
  rejectedBy: null,
  rejectedAt: "",
  approvedBy: null,
  approvedAt: "",
  type: "",
  vehicletype: null,
  campaign: null,
  remark: "",
  carpark: null,
  staffRemark: "",
  createdAt: "",
  rejectReason: "",
  applicationHardCopy: null,
  pendingReason: [],
  rejectReasonDetail: "",
};

const defaultSearchFilters = {
  no: "",
  campaign: "",
  applicantName: "",
  status: "",
  orderby: "_id",
  order: -1,
  limit: 30,
  page: 1,
  carpark: "",
  phone: "",
  createdAt: {
    from: "",
    to: "",
  },
  vehicleRegistrationMark: "",
  vehicleType: "",
  vehicleLicenseExpiredAt: {
    from: "",
    to: "",
  },
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultApplication,
  },
  searchFilters: {
    ...defaultSearchFilters,
  },
  totalPages: 1,
});

const applicationReducer = {
  application: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.APPLICATION_GET_LIST:
        state = {
          ...state,
          list: action.data.list,
          totalPages: action.data.totalPages,
        };
        return state;
      case ActionTypes.APPLICATION_SELECT:
        state = {
          ...state,
          selected: action.data
            ? { ...defaultApplication, ...action.data }
            : { ...defaultApplication },
        };
        return state;
      case ActionTypes.APPLICATION_UPDATE_SEARCH:
        state = {
          ...state,
          searchFilters: action.data,
        };
        return state;
      case ActionTypes.APPLICATION_RESET_SEARCH:
        state = {
          ...state,
          searchFilters: defaultSearchFilters,
        };
        return state;
      default:
        return state;
    }
  },
};

export default applicationReducer;
