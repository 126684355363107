import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import zh from "date-fns/locale/zh-TW";
import en from "date-fns/locale/en-US";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Collapse } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, { registerLocale } from "react-datepicker";
import actions, { useActions } from "../../../redux/actions";
import { status } from "../../../constants/applications";
import formattedDate from "../../../helpers/generalFunctions";
import i18n from "../../../i18n/i18n";

registerLocale("zh", zh);
registerLocale("en", en);

const SearchBar = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn btn-primary search-box-bar"
      onClick={() => setOpen(!open)}
      aria-controls="example-collapse-text"
      aria-expanded={open}
    >
      <i className="icofont icofont-search search-box-icon" />
      <span className="search-box-header-text">
        {t("profile.applications.search")}
      </span>
    </button>
  );
};

const SearchBox = (props) => {
  const { t, i18n } = useTranslation();
  const { fetchData, isRejected } = props;
  const { searchFilters } = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const [open, setOpen] = useState(true);
  const campaignActions = useActions(actions.CampaignActions);
  const { token } = useSelector((state) => state.account);
  const campaigns = useSelector((state) => state.campaign);
  const carparkActions = useActions(actions.CarparkActions);
  const carparks = useSelector((state) => state.carpark);
  const [query, setQuery] = useState(searchFilters);
  const accountActions = useActions(actions.AccountActions);
  const vehicleTypeList = useSelector((state) => state.vehicleType.list);

  useEffect(() => {
    campaignActions.getList(
      {},
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
    carparkActions.getList(
      token,
      () => {},
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
  }, []);

  const handleChange = (e) => {
    setQuery({
      ...query,
      [e.target.name]: e.target.value,
    });
  };

  const setDate = (date, type) => {
    setQuery({
      ...query,
      createdAt: {
        ...query.createdAt,
        [type]: formattedDate(date, false),
      },
    });
  };

  const setVehicleExpireDate = (date, type) => {
    setQuery({
      ...query,
      vehicleLicenseExpiredAt: {
        ...query.vehicleLicenseExpiredAt,
        [type]: formattedDate(date, false),
      },
    });
  };

  useEffect(() => {
    setQuery(searchFilters);
  }, [searchFilters]);

  return (
    <div className="search-box-container">
      <SearchBar open={open} setOpen={setOpen} />
      <Collapse in={open}>
        <div className="card search-box">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.applicationNumber")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="no"
                  type="text"
                  value={query.no}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.applicantName")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="applicantName"
                  type="text"
                  value={query.applicantName}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.phone")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="phone"
                  type="text"
                  value={query.phone}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.vehicleRegistrationMark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="vehicleRegistrationMark"
                  type="text"
                  value={query.vehicleRegistrationMark}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.campaign")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="campaign"
                  as="select"
                  value={query.campaign}
                  onChange={handleChange}
                >
                  {
                    <option key="" value="">
                      {t("profile.applications.notSelected")}
                    </option>
                  }
                  {campaigns.list.map((campaign) => {
                    return (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.carpark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="carpark"
                  as="select"
                  value={query.carpark}
                  onChange={handleChange}
                >
                  {
                    <option key="" value="">
                      {t("profile.applications.notSelected")}
                    </option>
                  }
                  {carparks.list.map((carpark) => {
                    return (
                      <option key={carpark._id} value={carpark._id}>
                        {i18n.language === "zh"
                          ? carpark.name_zh
                          : carpark.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.applications.vehicleType")}
              </Form.Label>
              <Col sm="9">
                {vehicleTypeList.reduce((acc, s) => {
                  if (s.value !== "rejected") {
                    acc.push(
                      <Form.Check
                        inline
                        name="vehicleType"
                        type="radio"
                        label={i18n.language === "en" ? s.name : s.name_zh}
                        value={s?._id}
                        checked={query.vehicleType === s._id}
                        onChange={handleChange}
                      />
                    );
                  }
                  return acc;
                }, [])}
                <Form.Check
                  inline
                  name="vehicleType"
                  type="radio"
                  label={t("profile.applications.all")}
                  value=""
                  checked={query.vehicleType === ""}
                  onChange={handleChange}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.vehicleLicenseExpireDateRange")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.from")}
                </span>
                <DatePicker
                  selected={Date.parse(query.vehicleLicenseExpiredAt.from)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setVehicleExpireDate(date, "from")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.to")}
                </span>
                <DatePicker
                  selected={Date.parse(query.vehicleLicenseExpiredAt.to)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setVehicleExpireDate(date, "to")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
            </Form.Group>

            {!isRejected && (
              <Form.Group
                as={Row}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form.Label column sm="3">
                  {t("profile.applications.status")}
                </Form.Label>
                <Col sm="6">
                  {status.reduce((acc, s) => {
                    if (s.value !== "rejected") {
                      acc.push(
                        <Form.Check
                          inline
                          name="status"
                          type="radio"
                          label={t(s.key)}
                          value={s.value}
                          checked={query.status === s.value}
                          onChange={handleChange}
                        />
                      );
                    }
                    return acc;
                  }, [])}
                  <Form.Check
                    inline
                    name="status"
                    type="radio"
                    label={t("profile.applications.all")}
                    value=""
                    checked={query.status === ""}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
            )}

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.dateRange")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.from")}
                </span>
                <DatePicker
                  selected={Date.parse(query.createdAt.from)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "from")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.to")}
                </span>
                <DatePicker
                  selected={Date.parse(query.createdAt.to)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "to")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
            </Form.Group>
          </Form>

          <div>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                setOpen(false);
                applicationActions.updateSearchFilters({
                  ...query,
                  page: 1,
                });
              }}
            >
              {t("profile.applications.search")}
            </button>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                setOpen(false);
                applicationActions.resetSearchFilters();
              }}
            >
              {t("profile.applications.reset")}
            </button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SearchBox;
