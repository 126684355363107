import ActionTypes from "./types";
import exportReportAPIHelper from "../../helpers/api/exportReportAPIHelper";

export function setSearchFilters(data) {
  return {
    type: ActionTypes.EXPORT_REPORT_UPDATE_SEARCH,
    data,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.EXPORT_REPORT_RESET_SEARCH,
  };
}

export function setReportType(type) {
  return {
    type: ActionTypes.EXPORT_REPORT_UPDATE_REPORT_TYPE,
    data: { type },
  };
}

export function exportReport(
  searchFilters = {},
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    exportReportAPIHelper.exportReport(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { id },
        } = response;
        dispatch({
          type: ActionTypes.EXPORT_REPORT_GENERATE_REPORT,
          data: { id },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function getReportProgress(
  reportId,
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    exportReportAPIHelper.getReportProgress(
      reportId,
      token,
      (response) => {
        const {
          responseDetail: { fileUrl },
        } = response;
        dispatch({
          type: ActionTypes.EXPORT_REPORT_GET_REPORT_PROGRESS,
          data: { url: fileUrl },
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const ExportReportAction = {
  setSearchFilters,
  resetSearchFilters,
  setReportType,
  exportReport,
  getReportProgress,
};
export default ExportReportAction;
