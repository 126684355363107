import baseAPIHelper from "./baseAPIHelper";

function getList(searchFilters, token, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/application?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchKey === "createdAt") {
      if (searchFilters.createdAt.from) {
        url += `&createdAt[from]=${searchFilters.createdAt.from}`;
      }
      if (searchFilters.createdAt.to) {
        url += `&createdAt[to]=${searchFilters.createdAt.to}`;
      }
      return;
    }

    if (searchKey === "vehicleLicenseExpiredAt") {
      if (searchFilters.vehicleLicenseExpiredAt.from) {
        url += `&vehicleLicenseExpiredAt[from]=${searchFilters.vehicleLicenseExpiredAt.from}`;
      }
      if (searchFilters.vehicleLicenseExpiredAt.to) {
        url += `&vehicleLicenseExpiredAt[to]=${searchFilters.vehicleLicenseExpiredAt.to}`;
      }
      return;
    }

    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function update(id, data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/application/${id}/status?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function generate(data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/applicationpool/generate?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function resendEmail(id, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/application/${id}/resend?access_token=${token}`;
  baseAPIHelper("POST", url, null, successCallback, failCallback);
}

function exportApp(id, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/application/campaign/${id}/export?access_token=${token}`;
  baseAPIHelper("POST", url, null, successCallback, failCallback);
}

const applicationAPIHelper = {
  getList,
  update,
  generate,
  resendEmail,
  exportApp,
};

export default applicationAPIHelper;
