import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Cookies from "universal-cookie/cjs";
import i18next from "i18next";
import { ActionCreators } from "../redux/actions";

const cookies = new Cookies();

class Loading extends Component {
  componentDidMount() {
    // if (!cookies.get('token') && !this.props.login) {
    //     window.location = `/${i18next.language}`// }
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <div className="loader">
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <h6>Loading</h6>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.account,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(ActionCreators, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
