import React, { Component } from "react";
import i18n from "../../i18n/i18n";
import themeColor from "../../styles/themeColor";

class Breadcrumb extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-lg-6">
              <h3>
                {this.props.title}
                <small>{i18n.t("subTitle")}</small>
              </h3>
            </div>
            <div className="col-lg-6">
              <ol className="breadcrumb pull-right">
                <li className="breadcrumb-item">
                  <a href="#!">
                    <i
                      style={{ color: themeColor.blueGreen }}
                      className="fa fa-home"
                    />
                  </a>
                </li>
                <li className="breadcrumb-item">{this.props.parent}</li>
                <li className="breadcrumb-item active">{this.props.title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
