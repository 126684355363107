import ActionTypes from "./types";
import reportAPIHelper from "../../helpers/api/reportAPIHelper";

/**
 * Get Manual Handling Applications
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getManualHandling(
  searchFilters = {},
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    reportAPIHelper.getManualHandling(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.REPORT_GET_MANUAL_HANDLING,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Get Exceptional Case Applications
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getExceptionalCase(
  searchFilters = {},
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    reportAPIHelper.getExceptionalCase(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.REPORT_GET_EXCEPTIONAL_CASE,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Get Daily Application Count
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getDailyCount(
  searchFilters = {},
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    reportAPIHelper.getDailyCount(
      searchFilters,
      token,
      (response) => {
        const {
          responseDetail: { daily },
        } = response;
        dispatch({
          type: ActionTypes.REPORT_GET_DAILY_COUNT,
          data: daily,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function updateSearchFilters(data) {
  return {
    type: ActionTypes.REPORT_UPDATE_SEARCH,
    data,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.REPORT_RESET_SEARCH,
  };
}

const ReportActions = {
  getManualHandling,
  getExceptionalCase,
  getDailyCount,
  updateSearchFilters,
  resetSearchFilters,
};
export default ReportActions;
