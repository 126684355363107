import React from "react";
import { useTranslation } from "react-i18next";
import { tabs } from "../../../constants/report";

const TabBar = (props) => {
  const { t } = useTranslation();
  const { active, setActiveTab } = props;

  return (
    <div style={{ marginBottom: 20, marginTop: 20 }}>
      {tabs.map((tab) => (
        <button
          type="button"
          className={`btn ${
            active === tab.value ? "btn-primary" : "btn-light"
          }`}
          value={tab.value}
          style={{ marginRight: 20 }}
          onClick={() => setActiveTab(tab.value)}
        >
          {t(tab.key)}
        </button>
      ))}
    </div>
  );
};

export default TabBar;
