import baseAPIHelper from "./baseAPIHelper";

function getList(token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/announcement?access_token=${token}`;
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function create(data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/announcement?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function update(id, data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/announcement/${id}?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function remove(id, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/announcement/delete/${id}?access_token=${token}`;
  baseAPIHelper("POST", url, null, successCallback, failCallback);
}

const announcementAPIHelper = {
  getList,
  create,
  update,
  remove,
};

export default announcementAPIHelper;
