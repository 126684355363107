import ActionTypes from "./types";
import settingAPIHelper from "../../helpers/api/settingAPIHelper";
import campaignAPIHelper from "../../helpers/api/campaignAPIHelper";

export function getSettings(
  token,
  successCallback = null,
  failCallback = null
) {
  return (dispatch) => {
    settingAPIHelper.getSettings(
      token,
      (response) => {
        const { responseDetail } = response;
        dispatch({
          type: ActionTypes.SETTING_GET_SETTINGS,
          data: responseDetail,
        });
        if (typeof successCallback === "function")
          successCallback(responseDetail);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function update(data, token, successCallback, failCallback) {
  return (dispatch) => {
    settingAPIHelper.update(
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.SETTING_UPDATE_SETTINGS,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const SettingActions = { getSettings, update };
export default SettingActions;
