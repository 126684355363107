export const tableHeader = [
  { value: "action", key: "profile.vehicles.action" },
  {
    value: "code",
    key: "profile.vehicles.code",
  },
  {
    value: "name",
    key: "profile.vehicles.name",
  },
  {
    value: "icon",
    key: "profile.vehicles.icon",
  },
  {
    value: "status",
    key: "profile.vehicles.status",
  },
];

export const status = [
  {
    value: "normal",
    key: "profile.vehicles.normal",
  },
  {
    value: "suspend",
    key: "profile.vehicles.suspend",
  },
];
