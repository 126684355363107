import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import Form from "react-bootstrap/Form";
import actions, { useActions } from "../../redux/actions";
import Loading from "../../components/loading";
import themeColor from "../../styles/themeColor";
import Language from "../../components/common/header/language.component";

const Login = (props) => {
  const { t, i18n } = useTranslation();
  const accountActions = useActions(actions.AccountActions);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    accountActions.login(
      credentials.username,
      credentials.password,
      () => {
        setLoading(false);
        setAlert({
          show: true,
          title: t("success"),
          content: t("login.success"),
          type: "success",
        });
        setTimeout(() => {
          window.location = `/${i18n.language}/home`;
        }, 1000);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          setAlert({
            show: true,
            title: t("error"),
            content: t("login.unauthorized"),
            type: "error",
          });
        } else {
          setAlert({
            show: true,
            title: t("error"),
            content: t("login.error"),
            type: "error",
          });
        }
        setLoading(false);
      }
    );
  };
  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <div>
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={handleCloseAlert}
      >
        {alert.content}
      </SweetAlert>
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="authentication-main">
            <div className="row">
              <div className="col-md-4 p-0">
                <div
                  className="auth-innerleft"
                  style={{ background: themeColor.blueGrey }}
                >
                  <div className="text-center">
                    <h4
                      style={{
                        textAlign: "center",
                        color: themeColor.blueGreen,
                      }}
                    >
                      {t("title")}
                    </h4>
                    <hr />
                    <div className="social-media">
                      <span style={{ color: "white" }}>{t("subTitle")}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 p-0">
                <div className="auth-innerright">
                  <div
                    className="authentication-box"
                    style={{ position: "relative" }}
                  >
                    <ul
                      style={{ minWidth: 120, position: "absolute", right: 0 }}
                    >
                      <Language style={{ top: 30 }} />
                    </ul>
                    <h4>{t("subTitle")}</h4>
                    <h6>{t("login.remarks")}</h6>
                    <div className="card mt-4 p-4 mb-0">
                      {loading ? (
                        <Loading login />
                      ) : (
                        <Form className="theme-form" onSubmit={handleOnSubmit}>
                          <div className="form-group">
                            <label className="col-form-label pt-0">
                              {t("login.username")}
                            </label>
                            <input
                              required
                              type="text"
                              className="form-control form-control-lg"
                              name="username"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group">
                            <label className="col-form-label">
                              {t("login.password")}
                            </label>
                            <input
                              required
                              type="password"
                              className="form-control form-control-lg"
                              name="password"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group form-row mt-3">
                            <div className="col-md-3">
                              <button type="submit" className="btn btn-primary">
                                {t("login.login")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Login);
