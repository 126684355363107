import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import FormHeader from "../../../components/formHeader";
import actions, { useActions } from "../../../redux/actions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const ipActions = useActions(actions.IPActions);
  const accountActions = useActions(actions.AccountActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }

    setIsLoading(true);
    setValidated(true);
    if (!formValues._id) {
      ipActions.create(
        formValues,
        account.token,
        () => {
          window.location.reload();
          setIsLoading(false);
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
          setIsLoading(false);
        }
      );
    } else {
      ipActions.update(
        formValues._id,
        formValues,
        account.token,
        () => {
          window.location.reload();
          setIsLoading(false);
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
          setIsLoading(false);
        }
      );
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t("security.ipRestriction.name")}
            </Form.Label>
            <Col sm="5">
              <Form.Control
                required
                name="name"
                type="text"
                value={formValues.name}
                onChange={handleChange}
                disabled={readOnly}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t("security.ipRestriction.address")}
            </Form.Label>
            <Col sm="5">
              <Form.Control
                required
                name="address"
                type="text"
                value={formValues.address}
                onChange={handleChange}
                disabled={readOnly}
              />
            </Col>
          </Form.Group>
        </Form>
      </div>
      <SpinnerOverlay loading={isLoading} />
    </Modal>
  );
}

export default Portal;
