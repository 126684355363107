import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultSearchFilters = {
  createdAt: {
    from: "",
    to: "",
  },
};

const initialState = Immutable({
  manualHandlingList: [],
  exceptionalCaseList: [],
  dailyCount: {},
  searchFilters: {
    ...defaultSearchFilters,
  },
});

const reportReducer = {
  report: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.REPORT_GET_MANUAL_HANDLING:
        return {
          ...state,
          manualHandlingList: action.data,
        };
      case ActionTypes.REPORT_GET_EXCEPTIONAL_CASE:
        return {
          ...state,
          exceptionalCaseList: action.data,
        };
      case ActionTypes.REPORT_GET_DAILY_COUNT:
        return {
          ...state,
          dailyCount: action.data,
        };
      case ActionTypes.REPORT_UPDATE_SEARCH:
        return {
          ...state,
          searchFilters: action.data,
        };
      case ActionTypes.REPORT_RESET_SEARCH:
        return {
          ...state,
          searchFilters: defaultSearchFilters,
        };
      default:
        return state;
    }
  },
};

export default reportReducer;
