import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultSearchFilters = {
  event: null,
  user: null,
  limit: 30,
  page: 1,
};

const initialState = Immutable({
  list: [],
  searchFilters: {
    ...defaultSearchFilters,
  },
  totalPages: 1,
});

const activityLogReducer = {
  activityLog: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.ACTIVITY_LOG_GET_LIST:
        return {
          ...state,
          list: action.data.list,
          totalPages: action.data.totalPages,
        };
      case ActionTypes.ACTIVITY_LOG_UPDATE_SEARCH:
        return {
          ...state,
          searchFilters: action.data,
        };
      case ActionTypes.ACTIVITY_LOG_RESET_SEARCH:
        return {
          ...state,
          searchFilters: defaultSearchFilters,
        };
      default:
        return state;
    }
  },
};

export default activityLogReducer;
