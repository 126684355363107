import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import actions, { useActions } from "../../../redux/actions";
import Breadcrumb from "../../../components/common/breadcrumb.component";

const Home = (props) => {
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);
  const accountActions = useActions(actions.AccountActions);

  // console.log(account, actions);
  return (
    <div>
      <Breadcrumb title={t("general.home.title")} parent={t("general.title")} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 p-0">
            <div className="text-center">
              <h2>{`${t("general.home.welcome")}!`}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 p-0" />
          <div className="col-md-4 p-0">
            <div className="text-center">
              <hr />
            </div>
          </div>
          <div className="col-md-4 p-0" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
