import baseAPIHelper from "./baseAPIHelper";

function getList(searchFilters, token, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/activitylog?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const activityLogAPIHelper = {
  getList,
};

export default activityLogAPIHelper;
