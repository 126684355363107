import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormHeader from "../../../components/formHeader";
import actions, { useActions } from "../../../redux/actions";
import { region, status } from "../../../constants/carparks";

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const carparkActions = useActions(actions.CarparkActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const accountActions = useActions(actions.AccountActions);

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      return;
    }

    setValidated(true);
    // Create
    if (!formValues._id) {
      carparkActions.create(
        formValues,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
    // Edit
    else {
      carparkActions.update(
        formValues._id,
        formValues,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.carparks.name")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name"
                  type="text"
                  value={formValues.name}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.carparks.name")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name_zh"
                  type="text"
                  value={formValues.name_zh}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.carparks.code")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="code"
                  type="text"
                  value={formValues.code}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.carparks.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.status === s.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.carparks.region")}
              </Form.Label>
              <Col sm="6">
                {region.map((r) => {
                  return (
                    <Form.Check
                      inline
                      name="region"
                      type="radio"
                      label={t(r.key)}
                      value={r.value}
                      checked={formValues.region === r.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.carparks.website")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="website"
                  type="text"
                  value={formValues.website}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.carparks.hotline")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="hotline"
                  type="text"
                  value={formValues.hotline}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default Portal;
