import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import actions, { useActions } from "../../../redux/actions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";
import i18n from "../../../i18n/i18n";

const Export = (props) => {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const applicationActions = useActions(actions.ApplicationActions);
  const accountActions = useActions(actions.AccountActions);
  const campaignActions = useActions(actions.CampaignActions);
  const campaignList = useSelector((state) => state.campaign.list);
  const [campaign, setCampaign] = useState({ name: "" });
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleExportApplication = (isDownload) => {
    setIsLoading(true);
    applicationActions.exportApplication(
      campaign._id,
      token,
      (response) => {
        if (!isDownload) {
          setAlert({
            show: true,
            title: t("success"),
            content: t("profile.applicationExport.success"),
            type: "success",
          });
        } else {
          const a = document.createElement("a");
          a.href = response.responseDetail.exportHistory.attachment.fileUrl;
          a.click();
        }
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        if (!isDownload) {
          setAlert({
            show: true,
            title: t("error"),
            content: t("profile.applicationExport.error"),
            type: "error",
          });
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    campaignActions.getList(
      {},
      token,
      (response) => {
        setCampaign(response.responseDetail.data[0]);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
      }
    );
  }, []);

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
  };

  return (
    <div className="app-export-container">
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={handleCloseAlert}
      >
        {alert.content}
      </SweetAlert>
      <div className="app-export-row">
        <span className="app-export-tag">
          {t("profile.applicationExport.campaign")}
        </span>

        <Dropdown className="app-export-dropdown">
          <Dropdown.Toggle className="btn btn-light">
            {campaign.name}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {campaignList.map((c) => (
              <Dropdown.Item onClick={() => setCampaign(c)}>
                {c.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <button
          type="button"
          className="btn btn-primary app-export-btn"
          onClick={() => handleExportApplication(false)}
        >
          {t("profile.applicationExport.send")}
        </button>

        <a>
          <button
            type="button"
            className="btn btn-primary app-export-btn"
            onClick={() => handleExportApplication(true)}
          >
            {t("profile.applicationExport.download")}
          </button>
        </a>
      </div>
      <SpinnerOverlay loading={isLoading} />
    </div>
  );
};

export default Export;
