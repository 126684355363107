import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, { registerLocale } from "react-datepicker";
import zh from "date-fns/locale/zh-TW";
import en from "date-fns/locale/en-US";
import FormHeader from "../../../components/formHeader";
import actions, { useActions } from "../../../redux/actions";
import { status } from "../../../constants/campaigns";
import formattedDate from "../../../helpers/generalFunctions";
import generalAPIHelper from "../../../helpers/api/generalAPIHelper";
import themeColor from "../../../styles/themeColor";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";

registerLocale("zh", zh);
registerLocale("en", en);

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const carparkList = useSelector((state) => state.carpark);
  const campaignActions = useActions(actions.CampaignActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const accountActions = useActions(actions.AccountActions);

  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false || !formValues.carpark) {
      return;
    }

    const data = {
      ...formValues,
      guidanceNote: formValues?.guidanceNote?._id || null,
    };

    setValidated(true);
    // Create
    if (!formValues._id) {
      campaignActions.create(
        data,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    } else {
      campaignActions.update(
        formValues._id,
        data,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const setDate = (date, type) => {
    setFormValues({
      ...formValues,
      [type]: formattedDate(date, false),
    });
  };

  const handleRemove = () => {
    setFormValues({
      ...formValues,
      guidanceNote: {
        _id: "",
        fileUrl: "",
      },
    });
  };

  const handleUpload = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    generalAPIHelper.uploadAttachment(
      account.token,
      formData,
      (response) => {
        setFormValues({
          ...formValues,
          guidanceNote: {
            _id: response.responseDetail._id,
            fileUrl: response.responseDetail.fileUrl,
          },
        });
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
      }
    );
  };

  useEffect(() => {
    if (carparkList.list[0] && !values.carpark) {
      setFormValues({ ...values, carpark: carparkList.list[0]._id });
    } else {
      setFormValues({ ...values });
    }
  }, [values, carparkList.list]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <div style={{ flex: 1 }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.campaigns.name")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name"
                  type="text"
                  value={formValues.name}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.campaigns.carpark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  value={formValues.carpark ? formValues.carpark._id : ""}
                  name="carpark"
                  onChange={handleChange}
                  disabled={readOnly}
                >
                  {carparkList.list.map((carpark) => {
                    return (
                      <option key={carpark._id} value={carpark._id}>
                        {i18n.language === "en"
                          ? carpark.name
                          : carpark.name_zh}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.period")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.from")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.from)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "from")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.to")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.to)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "to")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.parking")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.from")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.parkingFrom)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "parkingFrom")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.to")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.parkingTo)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "parkingTo")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.purchasePeriod")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.from")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.purchaseFrom)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "purchaseFrom")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.campaigns.to")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.purchaseTo)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "purchaseTo")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.ballotingDate")}
              </Form.Label>
              <Col sm="4">
                <DatePicker
                  selected={Date.parse(formValues.ballotingDate)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "ballotingDate")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.ballotingResultDate")}
              </Form.Label>
              <Col sm="4">
                <DatePicker
                  selected={Date.parse(formValues.ballotingResultDate)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "ballotingResultDate")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.campaigns.vehicleLicenseMinimumValidity")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="vehicleLicenseMinimumValidity"
                  type="number"
                  value={formValues.vehicleLicenseMinimumValidity}
                  onChange={handleChange}
                  min={0}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      checked={formValues.status === s.value}
                      value={s.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.campaigns.guidanceNote")}
              </Form.Label>
              <Col sm="6" style={{ display: "flex", flexDirection: "column" }}>
                {!(
                  formValues.guidanceNote && formValues.guidanceNote.fileUrl
                ) ? (
                  <label className="btn btn-primary" style={{ width: 100 }}>
                    {t("profile.campaigns.upload")}
                    <input
                      name="guidanceNote"
                      type="file"
                      onChange={(e) => handleUpload(e)}
                      style={{ display: "none" }}
                    />
                  </label>
                ) : (
                  <button
                    className="btn btn-primary"
                    style={{ width: 100 }}
                    onClick={() => handleRemove()}
                  >
                    {t("profile.campaigns.remove")}
                  </button>
                )}
                {formValues.guidanceNote && formValues.guidanceNote.fileUrl && (
                  <a
                    href={formValues.guidanceNote.fileUrl}
                    target="_blank"
                    style={{ color: themeColor.blueGreen }}
                  >
                    <img
                      alt={t("profile.campaigns.guidanceNote")}
                      src={formValues.guidanceNote.fileUrl}
                      style={{
                        maxHeight: 250,
                        objectFit: "contain",
                        marginRight: 20,
                        marginTop: 20,
                      }}
                    />
                  </a>
                )}
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
      <SpinnerOverlay loading={isLoading} />
    </Modal>
  );
}

export default Portal;
