import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Cell,
  Row as SRow,
  StickyTable,
} from "../../../components/stickytable";
import { rejectReasons, tableHeader } from "../../../constants/report";
import "react-datepicker/dist/react-datepicker.css";

const ExceptionalCaseTable = (props) => {
  const { t } = useTranslation();
  const { reports } = props;
  const [reportList, setReportList] = useState(reports);

  useEffect(() => {
    setReportList(reports);
  }, [reports]);

  return (
    <div style={{ overflow: "auto" }}>
      <StickyTable
        stickyHeaderCount={1}
        leftStickyColumnCount={0}
        borderWidth="1px"
        style={{
          height: reportList.length > 0 ? "50vh" : null,
        }}
      >
        <SRow>
          {tableHeader.exceptionalCase.map((item) => {
            return (
              <Cell>
                <button
                  type="button"
                  className="btn"
                  style={{
                    fontWeight: 900,
                    whiteSpace: "nowrap",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {t(item.key)}
                </button>
              </Cell>
            );
          })}
        </SRow>
        {reportList.map((applicationItem) => {
          const rejectReason = rejectReasons.find(
            (reason) => reason.value === applicationItem.rejectReason
          );
          return (
            <SRow>
              <Cell>{applicationItem.no || "-"}</Cell>
              <Cell>
                <SRow>{applicationItem.applicantName || "-"}</SRow>
              </Cell>
              <Cell>{applicationItem.phone || "-"}</Cell>
              <Cell>{applicationItem.email || "-"}</Cell>
              <Cell>{applicationItem.vehicleRegistrationMark || "-"}</Cell>
              <Cell>
                {applicationItem.status
                  ? t(`profile.applications.${applicationItem.status}`)
                  : "-"}
              </Cell>
              <Cell style={{ width: "100%" }}>
                {rejectReason ? t(rejectReason.key) : "-"}
              </Cell>
              <Cell>{applicationItem.rejectReasonDetail}</Cell>
              <Cell>{applicationItem.staffRemarkOption}</Cell>
              <Cell>{applicationItem.staffRemark}</Cell>
              <Cell>
                {moment(applicationItem.createdAt).format("YYYY/MM/DD HH:mm")}
              </Cell>
            </SRow>
          );
        })}
        {reportList.length < 1 ? (
          <tr>
            <td
              colSpan={tableHeader.exceptionalCase.length}
              style={{ textAlign: "center", padding: 20 }}
            >
              {t("noResults")}
            </td>
          </tr>
        ) : null}
      </StickyTable>
    </div>
  );
};

export default ExceptionalCaseTable;
