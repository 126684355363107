import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Cell,
  Row as SRow,
  StickyTable,
} from "../../../components/stickytable";
import {
  activityLogEvents,
  tableHeader,
} from "../../../constants/activityLogs";
import "react-datepicker/dist/react-datepicker.css";
import actions, { useActions } from "../../../redux/actions";

const ApplicationList = (props) => {
  const { t } = useTranslation();
  const { activityLogs } = props;
  const activityLogActions = useActions(actions.ActivityLogActions);
  const [activityLogList, setActivityLogList] = useState(activityLogs);

  useEffect(() => {
    setActivityLogList(activityLogs);
  }, [activityLogs]);

  return (
    <div style={{ overflow: "auto" }}>
      <StickyTable
        stickyHeaderCount={1}
        leftStickyColumnCount={0}
        borderWidth="1px"
        style={{
          height: activityLogList.length > 0 ? "45vh" : null,
        }}
      >
        <SRow>
          {tableHeader.map((item) => {
            return (
              <Cell
                scope="col"
                style={{
                  fontWeight: 900,
                  whiteSpace: "nowrap",
                }}
              >
                {t(item.key)}
              </Cell>
            );
          })}
        </SRow>
        {activityLogList.map((applicationItem) => {
          const logEvent =
            activityLogEvents.find(
              (event) => event.value === applicationItem.event
            ) || {};
          return (
            <SRow>
              <Cell>{logEvent ? t(logEvent.key) : "-"}</Cell>
              <Cell>{(applicationItem.createdBy || {}).name || "-"}</Cell>
              <Cell>
                {moment(applicationItem.createdAt).format("YYYY/MM/DD HH:mm")}
              </Cell>
            </SRow>
          );
        })}
        {activityLogList.length < 1 ? (
          <tr>
            <td
              colSpan={tableHeader.length}
              style={{ textAlign: "center", padding: 20 }}
            >
              {t("noResults")}
            </td>
          </tr>
        ) : null}
      </StickyTable>
    </div>
  );
};

export default ApplicationList;
