const MENUITEMS = [
  {
    title: "Home",
    key: "general.home.title",
    sidebarkey: "general.title",
    icon: "home",
    type: "link",
    path: "/home",
    sidebartitle: "General",
    active: true,
    name: "home",
  },
  {
    title: "Carparks",
    key: "profile.carparks.title",
    sidebarkey: "profile.title",
    icon: "location-pin",
    type: "link",
    path: "/carparks",
    sidebartitle: "Profile",
    active: false,
    badgeType: "primary",
    badgeValue: "new",
    name: "carpark",
  },
  {
    title: "Vehicles",
    key: "profile.vehicles.title",
    sidebarkey: "profile.title",
    icon: "car",
    type: "link",
    path: "/vehicles",
    active: false,
    name: "vehicletype",
  },
  {
    title: "Staff Accounts",
    key: "profile.staff.title",
    sidebarkey: "profile.title",
    icon: "user",
    type: "link",
    path: "/staffaccounts",
    active: false,
    name: "user",
  },
  {
    title: "Announcements",
    key: "profile.news.title",
    sidebarkey: "profile.title",
    icon: "file",
    type: "link",
    path: "/announcements",
    active: false,
    name: "announcement",
  },
  {
    title: "Campaigns",
    key: "profile.campaigns.title",
    sidebarkey: "profile.title",
    icon: "announcement",
    type: "link",
    path: "/campaigns",
    active: false,
    name: "campaign",
  },
  {
    title: "Applications",
    key: "profile.applications.title",
    sidebarkey: "profile.title",
    icon: "write",
    type: "link",
    path: "/applications",
    active: false,
    name: "application",
  },
  {
    title: "Applications Rejected",
    key: "profile.applications.rejectedTitle",
    sidebarkey: "profile.title",
    icon: "write",
    type: "link",
    path: "/applicationsRejected",
    active: false,
    name: "application",
  },
  {
    title: "Application Generator",
    key: "profile.applicationGenerator.title",
    sidebarkey: "profile.title",
    icon: "settings",
    type: "link",
    path: "/applicationgenerator",
    active: false,
    name: "application",
  },
  {
    title: "Application Export",
    key: "profile.applicationExport.title",
    sidebarkey: "profile.title",
    icon: "export",
    type: "link",
    path: "/applicationexport",
    active: false,
    name: "application",
  },
  {
    title: "Report",
    key: "profile.reports.title",
    sidebarkey: "profile.title",
    icon: "clipboard",
    type: "link",
    path: "/reports",
    active: false,
    name: "report",
  },
  {
    title: "Export Report",
    key: "profile.exportReports.title",
    sidebarkey: "profile.title",
    icon: "export",
    type: "link",
    path: "/exportreports",
    active: false,
    name: "report",
  },
  {
    title: "Activity Logs",
    key: "profile.activityLog.title",
    sidebarkey: "profile.title",
    icon: "archive",
    type: "link",
    path: "/activitylogs",
    active: false,
    name: "activitylog",
  },
  {
    title: "Setting",
    key: "profile.setting.title",
    sidebarkey: "profile.title",
    icon: "settings",
    type: "link",
    path: "/setting",
    active: false,
    name: "setting",
  },
  {
    title: "IP Restriction",
    key: "security.ipRestriction.title",
    sidebarkey: "security.title",
    icon: "link",
    type: "link",
    path: "/iprestriction",
    active: false,
    name: "iprestriction",
    sidebartitle: "Security",
  },
];

// eslint-disable-next-line import/prefer-default-export
export { MENUITEMS };
