const ActionTypes = {
  // Account
  ACCOUNT_LOGIN: "Account/LOGIN",
  ACCOUNT_LOGOUT: "Account/LOGOUT",
  ACCOUNT_GET_PERMISSION: "Account/GET_PERMISSION",

  // Carpark
  CARPARK_GET_LIST: "Carpark/GET_LIST",
  CARPARK_SELECT: "Carpark/SELECT",

  // Announcements
  ANNOUNCEMENT_GET_LIST: "Announcements/GET_LIST",
  ANNOUNCEMENT_SELECT: "Announcements/SELECT",

  // Applications
  APPLICATION_GET_LIST: "Application/GET_LIST",
  APPLICATION_SELECT: "Application/SELECT",
  APPLICATION_UPDATE_SEARCH: "Application/UPDATE_SEARCH",
  APPLICATION_RESET_SEARCH: "Application/RESET_SEARCH",
  APPLICATION_GENERATE: "Application/GENERATE",
  APPLICATION_RESEND_EMAIL: "Application/RESEND_EMAIL",
  APPLICATION_EXPORT: "Application/EXPORT",

  // Campaign
  CAMPAIGN_GET_LIST: "Campaign/GET_LIST",
  CAMPAIGN_GET_ACTIVE_LIST: "Campaign/GET_ACTIVE_LIST",
  CAMPAIGN_SELECT: "Campaign/SELECT",

  // Vehicle Type
  VEHICLE_TYPE_GET_LIST: "VehicleType/GET_LIST",
  VEHICLE_TYPE_SELECT: "VehicleType/SELECT",

  // User
  USER_GET_LIST: "User/GET_LIST",
  USER_SELECT: "User/SELECT",

  // Activity Log
  ACTIVITY_LOG_GET_LIST: "ActivityLog/GET_LIST",
  ACTIVITY_LOG_UPDATE_SEARCH: "ActivityLog/UPDATE_SEARCH",
  ACTIVITY_LOG_RESET_SEARCH: "ActivityLog/RESET_SEARCH",

  // Reports
  REPORT_GET_MANUAL_HANDLING: "Reports/GET_MANUAL_HANDLING",
  REPORT_GET_EXCEPTIONAL_CASE: "Reports/GET_EXCEPTIONAL_CASE",
  REPORT_GET_DAILY_COUNT: "Reports/GET_DAILY_COUNT",
  REPORT_UPDATE_SEARCH: "Reports/UPDATE_SEARCH",
  REPORT_RESET_SEARCH: "Reports/RESET_SEARCH",

  // Export Report
  EXPORT_REPORT_UPDATE_SEARCH: "ExportReport/UPDATE_SEARCH",
  EXPORT_REPORT_RESET_SEARCH: "ExportReport/RESET_SEARCH",
  EXPORT_REPORT_GENERATE_REPORT: "ExportReport/GENERATE_REPORT",
  EXPORT_REPORT_GET_REPORT_PROGRESS: "ExportReport/GET_REPORT_PROGRESS",
  EXPORT_REPORT_UPDATE_REPORT_TYPE: "ExportReport/UPDATE_REPORT_TYPE",

  // Settings
  SETTING_GET_SETTINGS: "Settings/GET_SETTINGS",
  SETTING_UPDATE_SETTINGS: "Settings/UPDATE_SETTINGS",

  // IP
  IP_GET_LIST: "IP/GET_LIST",
  IP_SELECT: "IP/SELECT",
  IP_UPDATE_ONE: "IP/UPDATE_ONE",
  IP_DELETE_ONE: "IP/DELETE_ONE",
  IP_CREATE: "IP/CREATE",
};

export default ActionTypes;
