import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { tableHeader } from "../../../constants/carparks";
import actions, { useActions } from "../../../redux/actions";
import Portal from "./portal";
import themeColor from "../../../styles/themeColor";

const ActionDropdown = (props) => {
  const carparkActions = useActions(actions.CarparkActions);
  const { t } = useTranslation();
  const { setShowModal, setAlert, carpark, isUpdateAllowed } = props;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="btn btn-sm">{t("option")}</Dropdown.Toggle>
        {isUpdateAllowed ? (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                carparkActions.select(carpark);
                setShowModal(true);
              }}
            >
              {t("edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                carparkActions.select(carpark);
                setAlert({
                  ...alert,
                  content: t("removeRecord"),
                  show: true,
                  type: "default",
                });
              }}
            >
              {t("remove")}
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                carparkActions.select(carpark);
                setShowModal(true);
              }}
            >
              {t("view")}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

const CarparkList = (props) => {
  const { t } = useTranslation();
  const { carparks } = props;
  const account = useSelector((state) => state.account);
  const carpark = useSelector((state) => state.carpark);
  const carparkActions = useActions(actions.CarparkActions);
  const [carparkList, setCarparkList] = useState(carparks);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isCreateAllowed, setIsCreateAllowed] = useState(false);
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false);

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
    window.location.reload();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleRemove = () => {
    carparkActions.remove(
      carpark.selected._id,
      account.token,
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("success"),
          content: "",
          type: "success",
        });
      },
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("error"),
          content: "",
          type: "error",
        });
      }
    );
  };

  useEffect(() => {
    setCarparkList(carparks);
  }, [carparks]);

  useEffect(() => {
    if (
      account.permission.general.includes("carpark-create") ||
      account.permission.general.includes("*")
    )
      setIsCreateAllowed(true);
    if (
      account.permission.general.includes("carpark-update") ||
      account.permission.general.includes("*")
    )
      setIsUpdateAllowed(true);
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      {isCreateAllowed && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: 20 }}
          onClick={() => {
            carparkActions.select(null);
            handleShowModal();
          }}
        >
          {t("add")}
        </button>
      )}
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={carpark.selected._id ? handleRemove : handleCloseAlert}
        onCancel={handleCloseAlert}
        showCancel={carpark.selected._id}
        cancelBtnCssClass="btn btn-light"
        cancelBtnStyle={{ color: themeColor.black }}
        confirmBtnText={t("ok")}
        cancelBtnText={t("cancel")}
      >
        {alert.content}
      </SweetAlert>
      <Portal
        show={showModal}
        values={carpark.selected}
        readOnly={!isUpdateAllowed}
      />
      <Table>
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                {t(item.key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {carparkList.map((carparkItem) => {
            return (
              <tr>
                <td>
                  <ActionDropdown
                    carpark={carparkItem}
                    setShowModal={setShowModal}
                    setAlert={setAlert}
                    isUpdateAllowed={isUpdateAllowed}
                  />
                </td>
                <td>{carparkItem.code}</td>
                <td>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    {`(${t("en")}) ${carparkItem.name}`}
                  </tr>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    {`(${t("zh")}) ${carparkItem.name_zh}`}
                  </tr>
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {carparkItem.region
                    ? t(`profile.carparks.${carparkItem.region.toLowerCase()}`)
                    : ""}
                </td>
                <td>
                  <a
                    href={carparkItem.website}
                    target="_blank"
                    style={{ color: themeColor.blueGreen }}
                  >
                    {carparkItem.website}
                  </a>
                </td>
                <td>{carparkItem.hotline}</td>
                <td>{t(`profile.carparks.${carparkItem.status}`)}</td>
              </tr>
            );
          })}
          {carparkList.length < 1 ? (
            <tr>
              <td colSpan={tableHeader.length} style={{ textAlign: "center" }}>
                {t("noResults")}
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
};

export default CarparkList;
