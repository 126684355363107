import baseAPIHelper from "./baseAPIHelper";

function login(username, password, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/auth/login`;
  const data = { username, password };
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function getPermission(token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/me?access_token=${token}`;
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

const authAPIHelper = {
  login,
  getPermission,
};

export default authAPIHelper;
