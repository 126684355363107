import baseAPIHelper from "./baseAPIHelper";

function getList(searchFilters, token, successCallback, failCallback) {
  let url = `${process.env.REACT_APP_API_DOMAIN}/api/campaign?access_token=${token}`;
  Object.keys(searchFilters).forEach((searchKey) => {
    if (searchFilters[searchKey]) {
      url += `&${searchKey}=${searchFilters[searchKey]}`;
    }
  });
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function getActiveList(token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/campaign/active?access_token=${token}`;
  baseAPIHelper("GET", url, null, successCallback, failCallback);
}

function create(data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/campaign?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function update(id, data, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/campaign/${id}?access_token=${token}`;
  baseAPIHelper("POST", url, data, successCallback, failCallback);
}

function remove(id, token, successCallback, failCallback) {
  const url = `${process.env.REACT_APP_API_DOMAIN}/api/campaign/delete/${id}?access_token=${token}`;
  baseAPIHelper("POST", url, null, successCallback, failCallback);
}

const campaignAPIHelper = {
  getList,
  getActiveList,
  create,
  update,
  remove,
};

export default campaignAPIHelper;
