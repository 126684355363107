import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import FormHeader from "../../../components/formHeader";
import { role, status } from "../../../constants/staffaccounts";
import actions, { useActions } from "../../../redux/actions";

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const userActions = useActions(actions.UserActions);
  const accountActions = useActions(actions.AccountActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }
    setValidated(true);

    const arr = {
      name: formValues.name,
      username: formValues.username,
      role: formValues.role,
      status: formValues.status,
    };

    if (formValues.role === "user") {
      arr.password = "12345678";
    } else if (formValues.password) {
      arr.password = formValues.password;
    }

    if (!formValues._id) {
      userActions.create(
        arr,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    } else {
      userActions.update(
        formValues._id,
        arr,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
  };
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.staff.name")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="name"
                  type="text"
                  value={formValues.name}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.staff.username")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="username"
                  type="text"
                  value={formValues.username}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            {formValues.role !== "user" && !readOnly ? (
              <div>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    {t("profile.staff.password")}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      name="password"
                      type="password"
                      value={formValues.password}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    {t("profile.staff.confirmPassword")}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      required={!!formValues.password}
                      name="confirmPassword"
                      type="password"
                      value={formValues.confirmPassword}
                      onChange={handleChange}
                      isInvalid={
                        formValues.password &&
                        formValues.confirmPassword !== formValues.password
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("profile.staff.pwdNotMatch")}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </div>
            ) : null}
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.staff.role")}
              </Form.Label>
              <Col sm="8">
                {role.map((r) => {
                  return (
                    <Form.Check
                      inline
                      name="role"
                      type="radio"
                      label={t(r.key)}
                      value={r.value}
                      checked={formValues.role === r.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.staff.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.status === s.value}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default Portal;
