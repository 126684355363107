import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import actions, { useActions } from "../../../redux/actions";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import Loading from "../../../components/loading";
import ManualHandlingTable from "./manualHandlingTable";
import TabBar from "./tabBar";
import ExceptionalCaseTable from "./exceptionalCaseTable";
import DailyCountTable from "./dailyCountTable";
import SearchBox from "./searchBox";

const Report = (props) => {
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const {
    searchFilters,
    manualHandlingList,
    exceptionalCaseList,
    dailyCount,
  } = useSelector((state) => state.report);
  const reportActions = useActions(actions.ReportActions);
  const accountActions = useActions(actions.AccountActions);

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const fetchManualHandlingList = () => {
    setIsLoading(true);
    reportActions.getManualHandling(
      searchFilters,
      token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  };

  const fetchExceptionalCaseList = () => {
    setIsLoading(true);
    reportActions.getExceptionalCase(
      searchFilters,
      token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  };

  const fetchDailyCount = () => {
    setIsLoading(true);
    reportActions.getDailyCount(
      searchFilters,
      token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  };

  function renderPageContent(index) {
    switch (index) {
      case 0:
        return (
          <DailyCountTable reports={dailyCount} fetchData={fetchDailyCount} />
        );
      case 1:
        return (
          <ManualHandlingTable
            reports={manualHandlingList}
            fetchData={fetchManualHandlingList}
          />
        );
      case 2:
        return (
          <ExceptionalCaseTable
            reports={exceptionalCaseList}
            fetchData={fetchExceptionalCaseList}
          />
        );
      default:
        return (
          <DailyCountTable reports={dailyCount} fetchData={fetchDailyCount} />
        );
    }
  }

  const fetchData = (index) => {
    switch (index) {
      case 0:
        return fetchDailyCount();
      case 1:
        return fetchManualHandlingList();
      case 2:
        return fetchExceptionalCaseList();
      default:
        return fetchDailyCount();
    }
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [searchFilters, activeTab]);

  return (
    <div>
      <Breadcrumb
        title={t("profile.reports.title")}
        parent={t("profile.title")}
      />
      <div className="container-fluid">
        <SearchBox />
      </div>
      <div className="container-fluid">
        <TabBar active={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="table-responsive">
              {isLoading ? <Loading /> : renderPageContent(activeTab)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Report);
