import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, { registerLocale } from "react-datepicker";
import zh from "date-fns/locale/zh-TW";
import en from "date-fns/locale/en-US";
import FormHeader from "../../../components/formHeader";
import actions, { useActions } from "../../../redux/actions";
import { status } from "../../../constants/announcements";
import generalAPIHelper from "../../../helpers/api/generalAPIHelper";
import formattedDate from "../../../helpers/generalFunctions";
import SpinnerOverlay from "../../../components/common/spinnerOverlay";

registerLocale("zh", zh);
registerLocale("en", en);

function Portal(props) {
  const { show, values, readOnly } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const accountActions = useActions(actions.AccountActions);
  const announcementActions = useActions(actions.AnnouncementActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const [isClickedSave, setIsClickedSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleSave = (e) => {
    setIsClickedSave(true);
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      return;
    }

    setValidated(true);

    const data = { ...formValues };
    data.image = formValues.image
      ? formValues.image._id === ""
        ? null
        : formValues.image._id
      : null;
    data.image_zh = formValues.image_zh
      ? formValues.image_zh._id === ""
        ? null
        : formValues.image_zh._id
      : null;

    // Create
    if (!formValues._id) {
      announcementActions.create(
        data,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
    // Edit
    else {
      announcementActions.update(
        formValues._id,
        data,
        account.token,
        () => {
          window.location.reload();
        },
        (error) => {
          const { responseCode } = error || {};
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          } else window.alert(error.responseMsg || "Unknown error.");
        }
      );
    }
  };
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const handleUpload = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const field = e.target.name;
    generalAPIHelper.uploadAttachment(
      account.token,
      formData,
      (response) => {
        setFormValues({
          ...formValues,
          [field]: {
            _id: response.responseDetail._id,
            fileUrl: response.responseDetail.fileUrl,
          },
        });
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
        setIsLoading(false);
      }
    );
  };
  const handleRemove = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: {
        _id: "",
        fileUrl: "",
      },
    });
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  const setDate = (date, type) => {
    setFormValues({
      ...formValues,
      [type]: formattedDate(date, false),
    });
  };

  return (
    <Modal show={show} backdrop="static" size="lg">
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader readOnly={readOnly} />
          <div style={{ flex: 1, overflow: "hidden" }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.subject")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="subject"
                  type="text"
                  value={formValues.subject}
                  onChange={handleChange}
                  isInvalid={formValues.subject === "" && isClickedSave}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.subject")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="subject_zh"
                  type="text"
                  value={formValues.subject_zh}
                  onChange={handleChange}
                  isInvalid={formValues.subject_zh === "" && isClickedSave}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.content")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="content"
                  as="textarea"
                  value={formValues.content}
                  onChange={handleChange}
                  isInvalid={formValues.content === "" && isClickedSave}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.content")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  required
                  name="content_zh"
                  as="textarea"
                  value={formValues.content_zh}
                  onChange={handleChange}
                  isInvalid={formValues.content_zh === "" && isClickedSave}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.url")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="url"
                  as="textarea"
                  value={formValues.url}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.url")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="url_zh"
                  as="textarea"
                  value={formValues.url_zh}
                  onChange={handleChange}
                  disabled={readOnly}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.news.postPeriod")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.news.from")}
                </span>
                <DatePicker
                  selected={Date.parse(formValues.postFrom)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "postFrom")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>{t("profile.news.to")}</span>
                <DatePicker
                  selected={Date.parse(formValues.postTo)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "postTo")}
                  locale={i18n.language}
                  disabled={readOnly}
                  popperPlacement="top-end"
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.news.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.status === s.value}
                      onChange={handleChange}
                      className="radio-primary"
                      disabled={readOnly}
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.image")} (${t("en")})`}
              </Form.Label>
              <Col sm="8">
                {!formValues.image || !formValues.image._id ? (
                  <label className="btn btn-primary">
                    {t("profile.news.upload")}
                    <Form.Control
                      name="image"
                      type="file"
                      onChange={handleUpload}
                      style={{ display: "none" }}
                      disabled={readOnly}
                    />
                  </label>
                ) : (
                  <button
                    name="image"
                    className="btn btn-primary"
                    type="button"
                    onClick={handleRemove}
                    disabled={readOnly}
                  >
                    {t("profile.news.remove")}
                  </button>
                )}
              </Col>
              <Col sm="3" />
              <Col sm="8">
                <img
                  alt=""
                  src={formValues.image ? formValues.image.fileUrl : ""}
                  style={{
                    maxWidth: "80vw",
                    maxHeight: "40vh",
                    objectFit: "contain",
                    marginTop: 20,
                  }}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {`${t("profile.news.image")} (${t("zh")})`}
              </Form.Label>
              <Col sm="8">
                {!formValues.image_zh || !formValues.image_zh._id ? (
                  <label className="btn btn-primary">
                    {t("profile.news.upload")}
                    <Form.Control
                      name="image_zh"
                      type="file"
                      onChange={handleUpload}
                      style={{ display: "none" }}
                      disabled={readOnly}
                    />
                  </label>
                ) : (
                  <button
                    name="image_zh"
                    className="btn btn-primary"
                    type="button"
                    onClick={handleRemove}
                    disabled={readOnly}
                  >
                    {t("profile.news.remove")}
                  </button>
                )}
              </Col>
              <Col sm="3" />
              <Col sm="8">
                <img
                  alt=""
                  src={formValues.image_zh ? formValues.image_zh.fileUrl : ""}
                  style={{
                    maxWidth: "80vw",
                    maxHeight: "40vh",
                    objectFit: "contain",
                    marginTop: 20,
                  }}
                />
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
      <SpinnerOverlay loading={isLoading} />
    </Modal>
  );
}

export default Portal;
