import React, { Component } from 'react';

class Footer extends Component {
    render(){
        return (
            <div>
                <footer className="main-footer">
                    <p>2019 © Let's Park Company (Hong Kong) Limited</p>
                </footer>
            </div>
        );
    }
}

export default Footer
