import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const initialState = Immutable({
  notificationEmail: "",
  exportEmail: "",
  passwordEmail: "",
  tdWebsite: "",
  tdWebsite_zh: "",
  guidanceNote: "",
  personalInformationCollectionStatement: "",
});

const settingReducer = {
  setting: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.SETTING_GET_SETTINGS:
        return {
          ...state,
          ...action.data,
        };
      case ActionTypes.SETTING_UPDATE_SETTINGS:
        return state;
      default:
        return state;
    }
  },
};

export default settingReducer;
