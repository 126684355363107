import { combineReducers } from "redux";
import accountReducer from "./account";
import carparkReducer from "./carpark";
import announcementReducer from "./announcement";
import campaignReducer from "./campaign";
import applicationReducer from "./application";
import vehicleTypeReducer from "./vehicletype";
import userReducer from "./user";
import activityLogReducer from "./activitylog";
import reportReducer from "./report";
import settingReducer from "./setting";
import ipReducer from "./ip";
import exportReportReducer from "./exportreport";

export default combineReducers(
  Object.assign(
    accountReducer,
    carparkReducer,
    announcementReducer,
    applicationReducer,
    campaignReducer,
    vehicleTypeReducer,
    userReducer,
    activityLogReducer,
    reportReducer,
    exportReportReducer,
    settingReducer,
    ipReducer
  )
);
