import Immutable from "seamless-immutable";
import ActionTypes from "../actions/types";

const defaultIP = {
  _id: "",
  name: "",
  address: "",
};

const initialState = Immutable({
  list: [],
  selected: {
    ...defaultIP,
  },
});

const ipReducer = {
  ip: (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.IP_GET_LIST:
        return {
          ...state,
          list: action.data,
        };
      case ActionTypes.IP_SELECT:
        return {
          ...state,
          selected: action.data
            ? { ...defaultIP, ...action.data }
            : { ...defaultIP },
        };
      default:
        return state;
    }
  },
};

export default ipReducer;
