class Poller {
  /**
   *
   * @param pollingFn function that poll the result
   * @param determineFn function that determine the result is success or not (true = success, false = failed)
   * @param successFn callback function that will be called when the result is success
   * @param failureFn callback function that will be called when the result is failed
   * @param maxRetry maximum retry, default is 10, if the result is not success after 10 retry, it will throw an error
   * @param pollingInterval polling interval in ms
   */
  constructor(pollingFn, failureFn, maxRetry = 10, pollingInterval = 5000) {
    if (!pollingFn || typeof pollingFn !== "function")
      throw new Error("Invalid arguments");
    this.pollingFn = pollingFn;
    this.failureFn = failureFn;
    this.maxRetry = maxRetry;
    this.pollingInterval = pollingInterval;
    this.retryCount = 0;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async retryCallback() {
    this.retryCount += 1;
    if (this.retryCount >= this.maxRetry) throw new Error("Max retry reached");
    await this.sleep(this.pollingInterval);
    await this.poll();
  }

  async poll() {
    try {
      await this.pollingFn(this.retryCallback);
    } catch (e) {
      this.failureFn(e);
    }
  }
}

export default Poller;
