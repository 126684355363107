import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import actions, { useActions } from "../../../redux/actions";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import Loading from "../../../components/loading";
import AnnouncementList from "./table";
import i18n from "../../../i18n/i18n";

const Announcements = (props) => {
  const { t } = useTranslation();
  const account = useSelector((state) => state.account);
  const accountActions = useActions(actions.AccountActions);
  const announcement = useSelector((state) => state.announcement);
  const announcementActions = useActions(actions.AnnouncementActions);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    announcementActions.getList(
      account.token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <div>
      <Breadcrumb title={t("profile.news.title")} parent={t("profile.title")} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <div className="table-responsive">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <AnnouncementList announcements={announcement.list} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Announcements);
