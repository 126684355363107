import React from "react";
import { useTranslation } from "react-i18next";

const FormHeader = (props) => {
  const { t } = useTranslation();
  const { leftTitle, rightTitle, onCancel, readOnly = false } = props;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "50%" }}>{leftTitle}</div>
        <div
          style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}
        >
          {rightTitle || (
            <>
              <button
                type="button"
                className="btn btn-light"
                style={{ marginRight: 20 }}
                onClick={() =>
                  onCancel ? onCancel() : window.location.reload()}
              >
                {t("cancel")}
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={readOnly}
              >
                {t("save")}
              </button>
            </>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

export default FormHeader;
