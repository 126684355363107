import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import actions, { useActions } from "../../../redux/actions";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import Loading from "../../../components/loading";
import ApplicationList from "./table";
import SearchBox from "./searchBox";
import "./application.css";
import PaginationBar from "./paginationBar";

const Applications = (props) => {
  const { isRejected } = props;
  const { t, i18n } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const { searchFilters, list, totalPages } = useSelector(
    (state) => state.application
  );
  const applicationActions = useActions(actions.ApplicationActions);
  const accountActions = useActions(actions.AccountActions);
  const account = useSelector((state) => state.account);

  const [isLoading, setIsLoading] = useState(false);
  const [breadcrumbTitle, setBreadcrumbTitle] = useState("");
  const vehicleTypeActions = useActions(actions.VehicleTypeActions);

  const fetchData = () => {
    setIsLoading(true);
    applicationActions.getList(
      {
        ...searchFilters,
        status: isRejected ? "rejected" : searchFilters?.status,
      },
      token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    applicationActions.resetSearchFilters();

    const title = isRejected
      ? t("profile.applications.rejectedTitle")
      : t("profile.applications.title");
    setBreadcrumbTitle(title);

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchFilters]);

  useEffect(() => {
    setIsLoading(true);
    vehicleTypeActions.getList(
      account.token,
      () => {
        setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        }
        setIsLoading(false);
      }
    );
  }, []);

  return (
    <div>
      <Breadcrumb title={breadcrumbTitle} parent={t("profile.title")} />
      <div className="container-fluid">
        <SearchBox fetchData={fetchData} isRejected={isRejected} />
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="table-responsive">
              {isLoading ? (
                <Loading />
              ) : (
                <ApplicationList
                  applications={list}
                  fetchData={fetchData}
                  isRejected={isRejected}
                />
              )}
              {totalPages > 0 && <PaginationBar fetchData={fetchData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Applications);
