import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import actions, { useActions } from "../../../redux/actions";
import Breadcrumb from "../../../components/common/breadcrumb.component";
import Loading from "../../../components/loading";
import ActivityLogList from "./table";
import "./activityLog.css";
import PaginationBar from "./paginationBar";
import { activityLogEvents } from "../../../constants/activityLogs";
import { sortingMethods } from "../../../constants/applications";
import i18n from "../../../i18n/i18n";

const ActivityLogs = (props) => {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.account);
  const user = useSelector((state) => state.user);
  const userActions = useActions(actions.UserActions);
  const accountActions = useActions(actions.AccountActions);
  const { searchFilters, list, totalPages } = useSelector(
    (state) => state.activityLog
  );
  const activityLogActions = useActions(actions.ActivityLogActions);

  const [isLoading, setIsLoading] = useState(false);
  const [eventFilter, setEventFilter] = useState(
    activityLogEvents.find((method) => method.value === searchFilters.event)
  );
  const [userFilter, setUserFilter] = useState(searchFilters.user);

  const fetchData = () => {
    setIsLoading(true);
    const API_COUNT = 2;
    let counter = 0;
    activityLogActions.getList(
      searchFilters,
      token,
      () => {
        counter += 1;
        if (counter >= API_COUNT) setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        counter += 1;
        if (counter >= API_COUNT) {
          setIsLoading(false);
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          }
        }
      }
    );
    userActions.getList(
      token,
      () => {
        counter += 1;
        if (counter >= API_COUNT) setIsLoading(false);
      },
      (error) => {
        const { responseCode } = error || {};
        counter += 1;
        if (counter >= API_COUNT) {
          setIsLoading(false);
          if (responseCode === 403) {
            window.alert(t("login.unauthorized"));
            accountActions.logout();
            window.location = `/${i18n.language}`;
          }
        }
      }
    );
  };

  useEffect(() => {
    activityLogActions.resetSearchFilters();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
    setEventFilter(
      activityLogEvents.find((method) => method.value === searchFilters.event)
    );
  }, [searchFilters.event]);

  const handleChangeEventFilter = (event) => {
    activityLogActions.updateSearchFilters({
      ...searchFilters,
      event,
    });
  };

  useEffect(() => {
    fetchData();
    setUserFilter(searchFilters.user);
  }, [searchFilters.user]);

  const handleChangeUserFilter = (user) => {
    activityLogActions.updateSearchFilters({
      ...searchFilters,
      user,
    });
  };

  return (
    <div>
      <Breadcrumb
        title={t("profile.activityLog.title")}
        parent={t("profile.title")}
      />
      <div className="container-fluid">
        <div className="filter-log-container">
          <div className="filter-container">
            <span>{`${t("profile.activityLog.event")}:`}</span>
            <Dropdown className="filter-dropdown">
              <Dropdown.Toggle className="btn btn-primary">
                {eventFilter ? t(eventFilter.key) : "-"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleChangeEventFilter(null)}>
                  -
                </Dropdown.Item>
                {activityLogEvents.map((method) => (
                  <Dropdown.Item
                    onClick={() => handleChangeEventFilter(method.value)}
                  >
                    {t(method.key)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="filter-container">
            <span>{`${t("profile.activityLog.createdBy")}:`}</span>
            <Dropdown className="filter-dropdown">
              <Dropdown.Toggle className="btn btn-primary">
                {(
                  (user.list || []).find(
                    (userItem) => userItem._id === userFilter
                  ) || {}
                ).name || "-"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleChangeUserFilter(null)}>
                  -
                </Dropdown.Item>
                {(user.list || []).map((userItem) => (
                  <Dropdown.Item
                    onClick={() => handleChangeUserFilter(userItem._id)}
                  >
                    {userItem.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <div className="table-responsive">
              {isLoading ? (
                <Loading />
              ) : (
                <ActivityLogList activityLogs={list} />
              )}
              {totalPages > 0 && <PaginationBar fetchData={fetchData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ActivityLogs);
