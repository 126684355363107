export const tableHeader = [
  { value: "", key: "" },
  { value: "action", key: "profile.applications.action" },
  {
    value: "no",
    key: "profile.applications.applicationNumber",
    sortKey: "no",
  },
  {
    value: "applicantName",
    key: "profile.applications.applicantName",
  },
  {
    value: "phone",
    key: "profile.applications.phone",
  },
  {
    value: "email",
    key: "profile.applications.email",
  },
  {
    value: "vehicleRegistrationMark",
    key: "profile.applications.vehicleRegistrationMark",
  },
  {
    value: "campaign",
    key: "profile.applications.campaign",
  },
  {
    value: "status",
    key: "profile.applications.status",
    sortKey: "status",
  },
  {
    value: "createdAt",
    key: "profile.applications.createdDate",
    sortKey: "_id",
  },
];

export const status = [
  {
    value: "pending",
    key: "profile.applications.pending",
  },
  {
    value: "rejected",
    key: "profile.applications.rejected",
  },
  {
    value: "approved",
    key: "profile.applications.approved",
  },
];

export const type = [
  {
    value: "online",
    key: "profile.applications.online",
  },
  {
    value: "offline",
    key: "profile.applications.offline",
  },
];

export const sortingMethods = [
  {
    value: "_id",
    key: "profile.applications.createdDate",
  },
  {
    value: "no",
    key: "profile.applications.applicationNumber",
  },
  {
    value: "status",
    key: "profile.applications.status",
  },
];

export const rejectReasons = [
  {
    // value: "Disabled Permit problem",
    value: "disabled_permit_problem",
    key: "profile.applications.disabledPermitProblem",
  },
  {
    // value: "Incorrect Licence Expires",
    value: "incorrect_licence_expires",
    key: "profile.applications.incorrectLicenceExpires",
  },
  {
    // value: "Vehicle Type not match",
    value: "vehicle_type_not_match",
    key: "profile.applications.vehicleTypeNotMatch",
  },
  {
    // value: "Incorrect Vehicle Registration Mark",
    value: "incorrect_vehicle_registration",
    key: "profile.applications.incorrectVehicleRegistrationMark",
  },
  {
    // value: "No Licence Submit",
    value: "no_licence_submit",
    key: "profile.applications.noLicenceSubmit",
  },
  {
    // value: "Cannot open the photo",
    value: "cannot_open_photo",
    key: "profile.applications.cantOpenThePhoto",
  },
  {
    // value: "Vehicle Type (Tricycle)",
    value: "vehicle_type_tricycle",
    key: "profile.applications.vehicleTypeTricycle",
  },
  {
    // value: "The photo is Incomplete or Blurry",
    value: "the_photo_is_incomplete_or_blurry",
    key: "profile.applications.incompleteOrBlurryPhoto",
  },
  {
    // value: "Other",
    value: "other",
    key: "profile.applications.other",
  },
];

export const pendingReasons = [
  {
    value: "vehicle_license_ocr_failed",
    key: "profile.applications.vehicleLicenseOcrFailed",
  },
  {
    value: "vehicle_license_ocr_not_match",
    key: "profile.applications.vehicleLicenseOcrNotMatch",
  },
  {
    value: "disable_person_parking_permit_ocr_failed",
    key: "profile.applications.disablePersonParkingPermitOcrFailed",
  },
  {
    value: "disable_person_parking_permit_ocr_not_match",
    key: "profile.applications.disablePersonParkingPermitOcrNotMatch",
  },
  {
    value: "remark_is_not_empty",
    key: "profile.applications.remarkIsNotEmpty",
  },
];

export const staffRemarkApproveOptions = [
  {
    // value: "Not expired on the date of application",
    value: "not_expired_on_date",
    key: "profile.applications.approveRemark.notExpiredOnTheDate",
  },
  {
    // value: "The Licence Date is not match. But the Licence is valid",
    value: "licence_date_not_match_licence_valid",
    key: "profile.applications.approveRemark.theLicenceDateIsNotMatch",
  },
  {
    // value:
    //   "The Licence Date is not match. But the Licence is valid. And Not expired on the date of application",
    value: "licence_date_not_match_licence_valid_not_expired_on_date",
    key:
      "profile.applications.approveRemark.theLicenceDateIsNotMatchNNotExpired",
  },
  {
    // value: "Application form in Carpark (Checked). Staff forgot upload",
    value: "application_checked_staff_forgot_upload",
    key: "profile.applications.approveRemark.applicationFormInCarpark",
  },
  {
    // value: "Vehicle Licence in Carpark (Checked). Staff forgot upload",
    value: "vehicle_licence_checked_staff_forgot_upload",
    key: "profile.applications.approveRemark.VehicleLicenceInCarpark",
  },
  {
    // value: "Other (Please Specify)",
    value: "other",
    key: "profile.applications.other",
  },
];

export const staffRemarkRejectOptions = [
  // {
  //   // value: "Incorrect input by Applicant",
  //   value: "incorrect_input_applicant",
  //   key: "profile.applications.rejectRemark.incorrectInputByApplicant",
  // },
  // {
  //   // value: "Incorrect input by Staff",
  //   value: "incorrect_input_staff",
  //   key: "profile.applications.rejectRemark.incorrectInputByStaff",
  // },
  {
    // value: "The applicant has been contacted and will reapply",
    value: "applicant_contacted_will_reapply",
    key: "profile.applications.rejectRemark.applicantHasBeenContacted",
  },
  {
    // value: "Left a Message",
    value: "left_a_message",
    key: "profile.applications.rejectRemark.LeftMessage",
  },
  {
    // value:
    //   "Contacted many times but failed to connect, unable to leave a message",
    value: "failed_to_connect_unable_leave_message",
    key: "profile.applications.rejectRemark.cantReachTheClient",
  },
  {
    // value: "Other (Please Specify)",
    value: "other",
    key: "profile.applications.other",
  },
];
