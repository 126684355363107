import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import zh from "date-fns/locale/zh-TW";
import en from "date-fns/locale/en-US";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Collapse } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DatePicker, { registerLocale } from "react-datepicker";
import actions, { useActions } from "../../../redux/actions";
import formattedDate from "../../../helpers/generalFunctions";

registerLocale("zh", zh);
registerLocale("en", en);

const SearchBar = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="btn btn-primary search-box-bar"
      onClick={() => setOpen(!open)}
      aria-controls="example-collapse-text"
      aria-expanded={open}
    >
      <i className="icofont icofont-search search-box-icon" />
      <span className="search-box-header-text">
        {t("profile.applications.search")}
      </span>
    </button>
  );
};

const SearchBox = (props) => {
  const { t, i18n } = useTranslation();
  const { searchFilters } = useSelector((state) => state.report);
  const reportActions = useActions(actions.ReportActions);
  const [open, setOpen] = useState(true);
  const [query, setQuery] = useState(searchFilters);

  const setDate = (date, type) => {
    setQuery({
      ...query,
      createdAt: {
        ...query.createdAt,
        [type]: formattedDate(date, false),
      },
    });
  };

  useEffect(() => {
    setQuery(searchFilters);
  }, [searchFilters]);

  return (
    <div className="search-box-container">
      <SearchBar open={open} setOpen={setOpen} />
      <Collapse in={open}>
        <div className="card search-box">
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.reports.dateRange")}
              </Form.Label>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.from")}
                </span>
                <DatePicker
                  selected={Date.parse(query.createdAt.from)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "from")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
              <Col sm="4">
                <span style={{ marginRight: 20 }}>
                  {t("profile.applications.to")}
                </span>
                <DatePicker
                  selected={Date.parse(query.createdAt.to)}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => setDate(date, "to")}
                  locale={i18n.language}
                  popperPlacement="right"
                />
              </Col>
            </Form.Group>
          </Form>

          <div>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                setOpen(false);
                reportActions.updateSearchFilters({
                  ...query,
                });
              }}
            >
              {t("profile.applications.search")}
            </button>
            <button
              type="button"
              className="btn btn-primary search-box-search-btn"
              onClick={() => {
                setOpen(false);
                reportActions.resetSearchFilters();
              }}
            >
              {t("profile.applications.reset")}
            </button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SearchBox;
