export const tableHeader = [
  { value: "action", key: "profile.news.action" },
  {
    value: "subject",
    key: "profile.news.subject",
  },
  {
    value: "image",
    key: "profile.news.image",
  },
  {
    value: "url",
    key: "profile.news.url",
  },
  {
    value: "status",
    key: "profile.news.status",
  },
  {
    value: "createdAt",
    key: "profile.news.createTime",
  },
];

export const status = [
  {
    value: "publish",
    key: "profile.news.publish",
  },
  {
    value: "draft",
    key: "profile.news.draft",
  },
];
