import React, { Component } from "react";
import i18next from "i18next";
import UserMenu from "./user-menu.component";
import Language from "./language.component";
import themeColor from "../../../styles/themeColor";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      sidebar: true,
      navMenus: false,
    };
  }

  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    } else {
      this.setState({ sidebar: true });
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("sidebar-close");
    }
  };

  toggle() {
    this.setState((prevState) => ({
      navMenus: !prevState.navMenus,
    }));
  }

  componentWillMount() {
    const contentwidth = window.innerWidth;
    if (contentwidth <= 991) {
      this.setState({ sidebar: false });
    }
  }

  componentDidMount() {
    const contentwidth = window.innerWidth;
    if (contentwidth <= 991) {
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    }
  }

  render() {
    return (
      <div className="page-main-header">
        <div className="main-header-left">
          <div className="logo-wrapper">
            <a
              href={`/${i18next.language}/home`}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span style={{ color: themeColor.blueGrey, fontWeight: "600" }}>
                {i18next.t("subTitle")}
              </span>
            </a>
          </div>
        </div>
        <div className="main-header-right row">
          <div className="mobile-sidebar">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <input
                  type="checkbox"
                  id="sidebar-toggle"
                  defaultChecked={this.state.sidebar}
                  onClick={this.openCloseSidebar}
                />
                <span className="switch-state" />
              </label>
            </div>
          </div>
          <div className="nav-right col">
            <ul className={`nav-menus ${this.state.navMenus ? "open" : ""}`}>
              <Language />
              <UserMenu />
            </ul>
            <div
              className="d-lg-none mobile-toggle"
              onClick={() => this.toggle()}
            >
              <i className="icon-more" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
