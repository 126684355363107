import ActionTypes from "./types";
import carparkAPIHelper from "../../helpers/api/carparkAPIHelper";

/**
 * Get list for Carparks
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function getList(token, successCallback = null, failCallback = null) {
  return (dispatch) => {
    carparkAPIHelper.getList(
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.CARPARK_GET_LIST,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Select the carpark for form input
 * @param {Object} data The data of selected carpark
 * @returns {{data: *, type: string}} Redux Action
 */
export function select(data) {
  return {
    type: ActionTypes.CARPARK_SELECT,
    data,
  };
}

/**
 * Create new carpark
 * @param {Object} data Data for creating a carpark
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function create(data, token, successCallback, failCallback) {
  return (dispatch) => {
    carparkAPIHelper.create(
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CARPARK_SELECT,
          data: null, // Reset selected carpark
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

/**
 * Update existing carpark
 * @param {String} id ID of the carpark to be updated
 * @param {Object} data Data for updating a carpark
 * @param {String} [token] Token for API calling
 * @param {Function} [successCallback=null] (Optional) Callback function after successful API call
 * @param {Function} [failCallback=null] (Optional) Callback function after failed API call
 * @returns {function(...[*]=)} Dispatch function of Redux action
 */
export function update(id, data, token, successCallback, failCallback) {
  return (dispatch) => {
    carparkAPIHelper.update(
      id,
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CARPARK_SELECT,
          data: null, // Reset selected carpark
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function remove(id, token, successCallback, failCallback) {
  return (dispatch) => {
    carparkAPIHelper.remove(
      id,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.CARPARK_SELECT,
          data: null, // Reset selected carpark
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const CarparkActions = { getList, select, create, update, remove };
export default CarparkActions;
