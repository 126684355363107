import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Cell,
  Row as SRow,
  StickyTable,
} from "../../../components/stickytable";
import { tableHeader } from "../../../constants/applications";
import "react-datepicker/dist/react-datepicker.css";
import Portal from "./portal";
import actions, { useActions } from "../../../redux/actions";

const ApplicationList = (props) => {
  const { t } = useTranslation();
  const { applications, fetchData, isRejected } = props;
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const [applicationList, setApplicationList] = useState(applications);
  const [showModal, setShowModal] = useState(false);
  const { searchFilters } = useSelector((state) => state.application);

  const handleChange = (orderby) => {
    let order = -1;
    if (orderby === searchFilters.orderby) {
      order = searchFilters.order * -1;
    }
    applicationActions.updateSearchFilters({
      ...searchFilters,
      orderby,
      order,
    });
  };

  useEffect(() => {
    setApplicationList(applications);
  }, [applications]);

  return (
    <div style={{ overflow: "auto" }}>
      <Portal
        show={showModal}
        values={application.selected}
        setShowModal={setShowModal}
        fetchData={fetchData}
        isRejected={isRejected}
      />
      <StickyTable
        stickyHeaderCount={1}
        leftStickyColumnCount={0}
        borderWidth="1px"
        style={{
          height: applicationList.length > 0 ? "50vh" : null,
        }}
      >
        <SRow>
          {tableHeader.map((item) => {
            return (
              <Cell>
                <button
                  type="button"
                  className="btn"
                  style={{
                    fontWeight: 900,
                    whiteSpace: "nowrap",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={() => {
                    if (item.sortKey) {
                      handleChange(item.sortKey);
                    }
                  }}
                >
                  {t(item.key)}
                  {item.sortKey && searchFilters.orderby === item.sortKey ? (
                    searchFilters.order === -1 ? (
                      <img
                        src={require("../../../assets/images/down-arrow.png")}
                        style={{ width: 15, marginLeft: 10 }}
                      />
                    ) : (
                      <img
                        src={require("../../../assets/images/up-arrow.png")}
                        style={{ width: 15, marginLeft: 10 }}
                      />
                    )
                  ) : null}
                </button>
              </Cell>
            );
          })}
        </SRow>
        {applicationList.map((applicationItem, index) => {
          return (
            <SRow>
              <Cell>{index + 1}</Cell>
              {!isRejected && (
                <Cell>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        applicationActions.select(applicationItem);
                        setShowModal(true);
                      }}
                    >
                      {t("edit")}
                    </button>
                  </div>
                </Cell>
              )}

              {isRejected && (
                <Cell>
                  <div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        applicationActions.select(applicationItem);
                        setShowModal(true);
                      }}
                    >
                      {t("view")}
                    </button>
                  </div>
                </Cell>
              )}

              <Cell>{applicationItem.no || "-"}</Cell>
              <Cell>
                <SRow>{applicationItem.applicantName || "-"}</SRow>
              </Cell>
              <Cell>{applicationItem.phone || "-"}</Cell>
              <Cell>{applicationItem.email || "-"}</Cell>
              <Cell>{applicationItem.vehicleRegistrationMark || "-"}</Cell>
              <Cell>{applicationItem?.campaign?.name || "-"}</Cell>
              <Cell>
                {applicationItem.status
                  ? t(`profile.applications.${applicationItem.status}`)
                  : "-"}
              </Cell>
              <Cell>
                {moment(applicationItem.createdAt).format("YYYY/MM/DD HH:mm")}
              </Cell>
            </SRow>
          );
        })}
        {applicationList.length < 1 ? (
          <tr>
            <td
              colSpan={tableHeader.length}
              style={{ textAlign: "center", padding: 20 }}
            >
              {t("noResults")}
            </td>
          </tr>
        ) : null}
      </StickyTable>
    </div>
  );
};

export default ApplicationList;
