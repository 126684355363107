import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import FormHeader from "../../../components/formHeader";
import {
  pendingReasons,
  rejectReasons,
  staffRemarkApproveOptions,
  staffRemarkPendingOptions,
  staffRemarkRejectOptions,
  status,
  type,
} from "../../../constants/applications";
import actions, { useActions } from "../../../redux/actions";
import themeColor from "../../../styles/themeColor";
import formattedDate from "../../../helpers/generalFunctions";

function Portal(props) {
  const { show, values, setShowModal, fetchData, isRejected } = props;
  const { t, i18n } = useTranslation();
  const account = useSelector((state) => state.account);
  const application = useSelector((state) => state.application);
  const applicationActions = useActions(actions.ApplicationActions);
  const accountActions = useActions(actions.AccountActions);
  const [formValues, setFormValues] = useState({ ...values });
  const [validated, setValidated] = useState(false);
  const [staffRemarkOptions, setStaffRemarkOptions] = useState([]);
  const [isOther, setIsOther] = useState(false);

  const isStaff = account.role === "staff";

  const [alert, setAlert] = useState({
    show: false,
    type: "default",
    content: "",
  });
  const handleSave = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
      return;
    }

    setValidated(true);

    const data = {
      status: formValues.status,
      staffRemark: formValues.staffRemark,
      staffRemarkOption: formValues.staffRemarkOption,
      rejectReason: formValues.rejectReason,
      rejectReasonDetail: formValues.rejectReasonDetail,
    };

    applicationActions.update(
      formValues._id,
      data,
      account.token,
      () => {
        setShowModal(false);
        fetchData();
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else window.alert(error.responseMsg || "Unknown error.");
      }
    );
  };
  const handleChange = (e) => {
    let { value } = e.target;
    if (e.target.name === "isPhysicalDisability") {
      value = e.target.value === "yes";
    }
    setFormValues({ ...formValues, [e.target.name]: value });
  };

  const resend = () => {
    applicationActions.resendEmail(
      formValues._id,
      account.token,
      () => {
        setAlert({
          ...alert,
          show: true,
          content: t("success"),
          type: "success",
        });
      },
      (error) => {
        const { responseCode } = error || {};
        if (responseCode === 403) {
          window.alert(t("login.unauthorized"));
          accountActions.logout();
          window.location = `/${i18n.language}`;
        } else
          setAlert({
            ...alert,
            show: true,
            content: t("error"),
            type: "error",
          });
      }
    );
  };
  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
  };

  const handleDownloadAs = async (url, info) => {
    const {
      carpark: { code: carparkCode },
      vehicletype: { identifier: vehicleTypeIdentifier },
      vehicleRegistrationMark,
      createdAt,
    } = info;

    const response = await fetch(url, { method: "get" });
    // hardcoded to JPG, format is unknown from asset server
    const name = `${vehicleRegistrationMark}_${vehicleTypeIdentifier}_${carparkCode}_${moment(
      createdAt
    ).format("YYYYMMDDhhmmss")}.jpg`;
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = name;
    a.click();
  };

  useEffect(() => {
    setFormValues({ ...values });
  }, [values]);

  useEffect(() => {
    if (formValues.status === "approved")
      setStaffRemarkOptions(staffRemarkApproveOptions);

    if (formValues.status === "rejected")
      setStaffRemarkOptions(staffRemarkRejectOptions);

    if (formValues.status === "pending") setStaffRemarkOptions([]);
  }, [formValues.status]);

  useEffect(() => {
    if (formValues.staffRemarkOption === "other") {
      setIsOther(true);
    }

    if (formValues.staffRemarkOption !== "other") {
      setFormValues({ ...formValues, staffRemark: "" });

      setIsOther(false);
    }
  }, [formValues.staffRemarkOption]);
  return (
    <Modal show={show} backdrop="static" size="lg">
      <SweetAlert
        show={alert.show}
        type={alert.type}
        onConfirm={handleCloseAlert}
        confirmBtnText={t("ok")}
      >
        {alert.content}
      </SweetAlert>
      <div style={{ padding: 20 }}>
        <Form onSubmit={handleSave} noValidate validated={validated}>
          <FormHeader
            readOnly={isRejected || isStaff}
            leftTitle={
              formValues.status !== "pending" ? (
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={resend}
                  style={{
                    borderColor: themeColor.blueGreen,
                    color: themeColor.blueGreen,
                  }}
                  disabled={isRejected || isStaff}
                >
                  {t("profile.applications.resend")}
                </button>
              ) : null
            }
            onCancel={() => setShowModal(false)}
          />
          <div style={{ flex: 1 }}>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.pendingReason")}
              </Form.Label>
              <Col sm="8">
                {formValues.pendingReason &&
                formValues.pendingReason.length >= 1
                  ? pendingReasons.reduce((acc, reason) => {
                      if (
                        formValues.pendingReason.find((r) => r === reason.value)
                      ) {
                        acc.push(
                          <Form.Control
                            plaintext
                            readOnly
                            value={t(reason.key)}
                          />
                        );
                      }
                      return acc;
                    }, [])
                  : "-"}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.applicationNumber")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.no ? formValues.no : "-"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.vehicleLicenseExpireDate")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    formValues.vehicleLicenseExpireDate
                      ? moment(formValues.vehicleLicenseExpireDate).format("DD/MM/YYYY")
                      : ""
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.applications.vehicleType")}
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    formValues.vehicletype
                      ? i18n.language === "en"
                        ? formValues.vehicletype.name
                        : formValues.vehicletype.name_zh
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.vehicleRegistrationMark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.vehicleRegistrationMark}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.applicantName")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.applicantName}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.phone")}
              </Form.Label>
              <Col sm="8">
                <Form.Control plaintext readOnly value={formValues.phone} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.email")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.email ? formValues.email : "-"}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.vehicleLicenseCopy")}
              </Form.Label>
              <Col sm="8" style={{ display: "flex", alignItems: "center" }}>
                {formValues.vehicleLicenseCopy ? (
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      const {
                        carpark,
                        vehicletype,
                        vehicleRegistrationMark,
                        createdAt,
                        isAutoApproved,
                      } = formValues;
                      handleDownloadAs(formValues.vehicleLicenseCopy.fileUrl, {
                        carpark,
                        vehicletype: isAutoApproved
                          ? vehicletype
                          : { identifier: "NA" },
                        vehicleRegistrationMark: isAutoApproved
                          ? vehicleRegistrationMark
                          : "NA",
                        createdAt,
                      });
                    }}
                    style={{
                      borderColor: themeColor.blueGreen,
                      color: themeColor.blueGreen,
                    }}
                  >
                    {t("profile.applications.download_copy")}
                  </button>
                ) : (
                  "-"
                )}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.applications.physicalDisability")}
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  inline
                  type="radio"
                  label={t("profile.applications.yes")}
                  checked={formValues.isPhysicalDisability}
                  disabled
                />
                <Form.Check
                  inline
                  type="radio"
                  label={t("profile.applications.no")}
                  checked={!formValues.isPhysicalDisability}
                  disabled
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.disablePersonParkingPermit")}
              </Form.Label>
              <Col sm="8" style={{ display: "flex", alignItems: "center" }}>
                {formValues.disablePersonParkingPermit ? (
                  <a
                    href={formValues.disablePersonParkingPermit.fileUrl}
                    target="_blank"
                    style={{ color: themeColor.blueGreen }}
                  >
                    {formValues.disablePersonParkingPermit.fileUrl.replace(
                      process.env.REACT_APP_AWS_DOMAIN,
                      ""
                    )}
                  </a>
                ) : (
                  "-"
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.applicationHardCopy")}
              </Form.Label>
              <Col sm="8" style={{ display: "flex", alignItems: "center" }}>
                {formValues.applicationHardCopy ? (
                  <a
                    href={formValues.applicationHardCopy.fileUrl}
                    target="_blank"
                    style={{ color: themeColor.blueGreen }}
                  >
                    {formValues.applicationHardCopy.fileUrl.replace(
                      process.env.REACT_APP_AWS_DOMAIN,
                      ""
                    )}
                  </a>
                ) : (
                  "-"
                )}
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.applications.type")}
              </Form.Label>
              <Col sm="6">
                {type.map((s) => {
                  return (
                    <Form.Check
                      inline
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.type === s.value}
                      disabled
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.campaign")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.campaign ? formValues.campaign.name : "-"}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.carpark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    formValues.carpark
                      ? i18n.language === "en"
                        ? formValues.carpark.name
                        : formValues.carpark.name_zh
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.remark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={formValues.remark ? formValues.remark : "-"}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Form.Label column sm="3">
                {t("profile.applications.status")}
              </Form.Label>
              <Col sm="6">
                {status.map((s) => {
                  return (
                    <Form.Check
                      inline
                      name="status"
                      type="radio"
                      label={t(s.key)}
                      value={s.value}
                      checked={formValues.status === s.value}
                      onChange={handleChange}
                      disabled={
                        isRejected ||
                        isStaff ||
                        ((application.selected.status === "approved" ||
                          application.selected.status === "rejected") &&
                          s.value === "pending")
                      }
                    />
                  );
                })}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.rejectReason")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  name="rejectReason"
                  isInvalid={
                    (formValues.rejectReason === "" ||
                      !formValues.rejectReason) &&
                    formValues.status === "rejected"
                  }
                  required={formValues.status === "rejected"}
                  disabled={isRejected || isStaff}
                >
                  {
                    <option
                      selected={
                        !formValues.rejectReason ||
                        formValues.rejectReason === ""
                      }
                      value=""
                    >
                      {t("profile.applications.notSelected")}
                    </option>
                  }
                  {rejectReasons.map((reason) => {
                    return (
                      <option
                        selected={formValues.rejectReason === reason.value}
                        value={reason.value}
                      >
                        {t(reason.key)}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.rejectReasonDetail")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="rejectReasonDetail"
                  as="textarea"
                  value={formValues.rejectReasonDetail}
                  onChange={handleChange}
                  required={formValues.rejectReason === "Other"}
                  isInvalid={
                    formValues.rejectReason === "Other" &&
                    formValues.rejectReasonDetail === ""
                  }
                  disabled={isRejected || isStaff}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.staffRemark")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  onChange={handleChange}
                  name="staffRemarkOption"
                  disabled={isRejected || isStaff}
                  // required={
                  //   formValues.status === "rejected" ||
                  //   formValues.status === "approved"
                  // }
                >
                  {
                    <option
                      selected={
                        !formValues.staffRemarkOption ||
                        formValues.staffRemarkOption === ""
                      }
                      value=""
                    >
                      {t("profile.applications.notSelected")}
                    </option>
                  }

                  {staffRemarkOptions.map((reason) => {
                    return (
                      <option
                        selected={formValues.staffRemarkOption === reason.value}
                        value={reason.value}
                      >
                        {t(reason.key)}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.staffRemarkDetail")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  name="staffRemark"
                  as="textarea"
                  value={formValues.staffRemark}
                  onChange={handleChange}
                  disabled={isRejected || isStaff || !isOther}
                  // isInvalid={formValues.staffRemark}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.rejectedBy")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    formValues.rejectedBy
                      ? formValues.rejectedBy.name || "-"
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.rejectedAt")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    formValues.rejectedAt
                      ? formattedDate(new Date(formValues.rejectedAt))
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.approvedBy")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    formValues.approvedBy
                      ? formValues.approvedBy.name || "-"
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                {t("profile.applications.approvedAt")}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  plaintext
                  readOnly
                  value={
                    formValues.approvedAt
                      ? formattedDate(new Date(formValues.approvedAt))
                      : "-"
                  }
                />
              </Col>
            </Form.Group>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default Portal;
