import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Cell,
  Row as SRow,
  StickyTable,
} from "../../../components/stickytable";
import { tableHeader } from "../../../constants/report";
import "react-datepicker/dist/react-datepicker.css";

const DailyCountTable = (props) => {
  const { t } = useTranslation();
  const { reports } = props;
  const [reportList, setReportList] = useState(reports);

  function renderTableRow() {
    const rows = [];
    Object.keys(reportList).forEach((date) =>
      rows.push(
        <SRow>
          <Cell style={{ textAlign: "center" }}>{date}</Cell>
          <Cell style={{ textAlign: "center" }}>{reportList[date].online}</Cell>
          <Cell style={{ textAlign: "center" }}>
            {reportList[date].offline}
          </Cell>
        </SRow>
      )
    );
    return rows;
  }

  useEffect(() => {
    setReportList(reports);
  }, [reports]);

  return (
    <div style={{ overflow: "auto" }}>
      <StickyTable
        stickyHeaderCount={1}
        leftStickyColumnCount={0}
        borderWidth="1px"
        style={{
          height: "50vh",
        }}
      >
        <SRow>
          {tableHeader.dailyCount.map((item) => {
            return (
              <Cell style={{ textAlign: "center" }}>
                <button
                  type="button"
                  className="btn"
                  style={{
                    fontWeight: 900,
                    whiteSpace: "nowrap",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {t(item.key)}
                </button>
              </Cell>
            );
          })}
        </SRow>
        {renderTableRow()}
        {Object.keys(reportList).length < 1 ? (
          <tr>
            <td
              colSpan={tableHeader.dailyCount.length}
              style={{ textAlign: "center", padding: 20 }}
            >
              {t("noResults")}
            </td>
          </tr>
        ) : null}
      </StickyTable>
    </div>
  );
};

export default DailyCountTable;
