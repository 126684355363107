import ActionTypes from "./types";
import ipAPIHelper from "../../helpers/api/ipAPIHelper";

export function getList(token, successCallback = null, failCallback = null) {
  return (dispatch) => {
    ipAPIHelper.getList(
      token,
      (response) => {
        const {
          responseDetail: { data },
        } = response;
        dispatch({
          type: ActionTypes.IP_GET_LIST,
          data,
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function select(data) {
  return {
    type: ActionTypes.IP_SELECT,
    data,
  };
}

export function create(data, token, successCallback, failCallback) {
  return (dispatch) => {
    ipAPIHelper.create(
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.IP_SELECT,
          data: null, // Reset selected ip
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function update(id, data, token, successCallback, failCallback) {
  return (dispatch) => {
    ipAPIHelper.update(
      id,
      data,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.IP_SELECT,
          data: null, // Reset selected ip
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

export function remove(id, token, successCallback, failCallback) {
  return (dispatch) => {
    ipAPIHelper.remove(
      id,
      token,
      (response) => {
        const { responseCode } = response;
        if (responseCode !== 200) {
          if (typeof failCallback === "function") failCallback(response);
          return;
        }
        dispatch({
          type: ActionTypes.IP_SELECT,
          data: null, // Reset selected ip
        });
        if (typeof successCallback === "function") successCallback(response);
      },
      (error) => {
        if (typeof failCallback === "function") failCallback(error);
      }
    );
  };
}

const IPActions = { getList, select, create, update, remove };
export default IPActions;
