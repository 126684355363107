import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { tableHeader } from "../../../constants/vehicles";
import Portal from "./portal";
import actions, { useActions } from "../../../redux/actions";
import themeColor from "../../../styles/themeColor";

const ActionDropdown = (props) => {
  const vehicleTypeActions = useActions(actions.VehicleTypeActions);
  const { t } = useTranslation();
  const { setShowModal, setAlert, vehicle, isUpdateAllowed } = props;

  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle className="btn btn-sm">{t("option")}</Dropdown.Toggle>
        {isUpdateAllowed ? (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                vehicleTypeActions.select(vehicle);
                setShowModal(true);
              }}
            >
              {t("edit")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                vehicleTypeActions.select(vehicle);
                setAlert({
                  ...alert,
                  content: t("removeRecord"),
                  show: true,
                  type: "default",
                });
              }}
            >
              {t("remove")}
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                vehicleTypeActions.select(vehicle);
                setShowModal(true);
              }}
            >
              {t("view")}
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

const VehicleList = (props) => {
  const { t } = useTranslation();
  const { vehicles } = props;
  const [vehicleList, setVehicleList] = useState(vehicles);
  const account = useSelector((state) => state.account);
  const vehicleType = useSelector((state) => state.vehicleType);
  const vehicleTypeActions = useActions(actions.VehicleTypeActions);
  const [showModal, setShowModal] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    type: "default",
    content: "",
  });
  const [isCreateAllowed, setIsCreateAllowed] = useState(false);
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false);

  const handleCloseAlert = () => {
    setAlert({ ...alert, show: false });
    window.location.reload();
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };
  const handleRemove = () => {
    vehicleTypeActions.remove(
      vehicleType.selected._id,
      account.token,
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("success"),
          content: "",
          type: "success",
        });
      },
      () => {
        setAlert({
          ...alert,
          show: true,
          title: t("error"),
          content: "",
          type: "error",
        });
      }
    );
  };

  useEffect(() => {
    setVehicleList(vehicles);
  }, [vehicles]);

  useEffect(() => {
    if (
      account.permission.general.includes("vehicletype-create") ||
      account.permission.general.includes("*")
    )
      setIsCreateAllowed(true);
    if (
      account.permission.general.includes("vehicletype-update") ||
      account.permission.general.includes("*")
    )
      setIsUpdateAllowed(true);
  }, []);

  return (
    <div style={{ overflow: "auto" }}>
      {isCreateAllowed && (
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: 20 }}
          onClick={() => {
            vehicleTypeActions.select(null);
            handleShowModal();
          }}
        >
          {t("add")}
        </button>
      )}
      <SweetAlert
        show={alert.show}
        type={alert.type}
        title={alert.title}
        onConfirm={vehicleType.selected._id ? handleRemove : handleCloseAlert}
        onCancel={handleCloseAlert}
        showCancel={vehicleType.selected._id}
        cancelBtnCssClass="btn btn-light"
        cancelBtnStyle={{ color: themeColor.black }}
        confirmBtnText={t("ok")}
        cancelBtnText={t("cancel")}
      >
        {alert.content}
      </SweetAlert>
      <Portal
        show={showModal}
        values={vehicleType.selected}
        readOnly={!isUpdateAllowed}
      />
      <Table>
        <thead>
          <tr>
            {tableHeader.map((item) => (
              <th scope="col" style={{ whiteSpace: "nowrap" }}>
                {t(item.key)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {vehicleList.map((vehicle) => {
            return (
              <tr>
                <td>
                  <ActionDropdown
                    vehicle={vehicle}
                    setShowModal={setShowModal}
                    setAlert={setAlert}
                    isUpdateAllowed={isUpdateAllowed}
                  />
                </td>
                <td>{vehicle.code}</td>
                <td>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    {" "}
                    {`(${t("en")}) ${vehicle.name}`}
                  </tr>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    {" "}
                    {`(${t("zh")}) ${vehicle.name_zh}`}
                  </tr>
                </td>
                {/* <td style={{textOverflow: "ellipsis", maxWidth: "10vw", overflow: "hidden", whiteSpace: "nowrap" }}>{vehicle.icon}</td> */}
                <td>
                  <img
                    alt=""
                    src={vehicle.icon ? vehicle.icon.fileUrl : ""}
                    style={{
                      maxWidth: "10vw",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td>{t(`profile.vehicles.${vehicle.status}`)}</td>
              </tr>
            );
          })}
          {vehicleList.length < 1 ? (
            <tr>
              <td colSpan={tableHeader.length} style={{ textAlign: "center" }}>
                {t("noResults")}
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
};

export default VehicleList;
